import Layout from '../../Layout'
import {
    Typography,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Snackbar,
    CircularProgress,
    Chip,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import MuiAlert from '@material-ui/lab/Alert'
import { SearchableSelect } from '../../SearchableSelect/SearchableSelect'
import { get } from 'lodash'

const useStyles = makeStyles((theme) => ({
    uploadFileInput: {
        float: 'right',
        textTransform: 'unset',
    },
    gridItemSearch: {
        width: '100%',
    },
    gridItem: {
        padding: '.75rem',
        width: '100%',
        margin: 'auto',
    },
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    sectionHeader: {
        padding: '1.2rem 0',
        display: 'flex',
        flexWrap: 'nowrap',
        position: 'static',
        margin: 'auto',
    },
    columnChip: {
        padding: '1rem',
        margin: '.25rem',
    },
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const InfoPanel = (props) => {
    const { children, value, index } = props

    return (
        <div>
            {value === index && (
                <Typography component={'span'}>{children}</Typography>
            )}
        </div>
    )
}

const ScraperStats = (props) => {
    const [alertOpen, setAlertOpen] = useState(null)
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(null)
    const [scrapers, setScrapers] = useState([])
    const [selectedValue, setSelectedValue] = useState(null)
    const [selectedInfo, setSelectedInfo] = useState({})
    const classes = useStyles()

    useEffect(() => {
        const getSuggestions = async () => {
            let res = await axios.get(`/scraper_stats/list`)
            if (res.status !== 200) {
                setScrapers([])
            } else {
                if (res.data.length === 0) {
                    showAlert('No scraper classes found')
                } else {
                    setScrapers(res.data)
                }
            }
        }
        getSuggestions()
    }, [setScrapers])

    const handleChange = async (event) => {
        setLoading(true)
        const index = event.target.value
        if (index === undefined) {
            setSelectedValue(null)
        } else {
            setSelectedValue(Number(scrapers[index].id))
            const results = await axios.get(
                `/scraper_stats/${scrapers[index].name}`
            )
            setSelectedInfo(results.data)
        }
        setLoading(false)
    }

    const showAlert = (message) => {
        setAlertOpen(true)
        setAlertMessage(message)
    }

    return (
        <Layout>
            <div style={{ marginBottom: '2rem' }}>
                <Typography component="div" className={classes.sectionHeader}>
                    <Typography variant="h5" gutterBottom>
                        Scraper Stats
                    </Typography>
                </Typography>
                <Grid container>
                    <Grid item xs={6} className={classes.gridItemSearch}>
                        <SearchableSelect
                            key={selectedValue}
                            label="Select Scraper"
                            value={selectedValue}
                            onChange={handleChange}
                            options={scrapers}
                            keyPropFn={(option) => option.id}
                            valuePropFn={(option) => option.friendly_name}
                            showAll
                            removeSelectionText
                            alwaysFocusTextFieldOnEnter
                        />
                    </Grid>
                    {selectedInfo !== null &&
                        selectedInfo.columns !== undefined && (
                            <Grid item xs={12} className={classes.gridItem}>
                                <Grid item xs={12} className={classes.gridItem}>
                                    <Typography variant="subtitle1">
                                        Report Date:{' '}
                                        {get(selectedInfo, 'report_date')}
                                    </Typography>
                                    <hr />
                                    <Typography variant="subtitle1">
                                        Scraper Table Columns:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.gridItem}>
                                    {get(selectedInfo, 'columns', []).map(
                                        (element, idx) => (
                                            <Chip
                                                color="primary"
                                                className={classes.columnChip}
                                                key={idx}
                                                label={element}
                                            ></Chip>
                                        )
                                    )}
                                    <hr />
                                </Grid>
                            </Grid>
                        )}
                    <Grid item xs={12} className={classes.gridItem}>
                        <InfoPanel
                            value={selectedValue}
                            index={selectedValue}
                            key={selectedValue}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {Object.keys(
                                            get(selectedInfo, 'data[0]', {})
                                        ).map((key) => (
                                            <TableCell>{key}</TableCell>
                                        ))}
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(
                                        get(selectedInfo, 'data', [])
                                    ).map((idx) => (
                                        <TableRow key={idx}>
                                            {Object.values(
                                                selectedInfo.data[idx]
                                            ).map((val) => (
                                                <TableCell>{val}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </InfoPanel>
                    </Grid>
                    {loading && (
                        <div className={classes.loadingIndicator}>
                            <CircularProgress color="primary" size={75} />
                        </div>
                    )}
                    {selectedValue !== null &&
                        selectedInfo.data === undefined &&
                        !loading && (
                            <Typography
                                component="div"
                                className={classes.sectionHeader}
                            >
                                <Typography variant="h5" gutterBottom>
                                    {'No stats found for scraper'}
                                </Typography>
                            </Typography>
                        )}
                    {selectedValue === null && !loading && (
                        <Typography
                            component="div"
                            className={classes.sectionHeader}
                        >
                            <Typography variant="h5" gutterBottom>
                                {'No Scraper selected yet...'}
                            </Typography>
                        </Typography>
                    )}
                </Grid>
            </div>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={(e, r) => setAlertOpen(false)}
            >
                <Alert onClose={(e, r) => setAlertOpen(false)} severity="error">
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Layout>
    )
}
export default ScraperStats
