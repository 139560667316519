import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import NotesIcon from '@material-ui/icons/Notes'
import StopIcon from '@material-ui/icons/Stop'
import TextField from '@material-ui/core/TextField'
import Delete from '@material-ui/icons/Delete'

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Tooltip,
    Typography,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core'
import Editor from '@monaco-editor/react'

const DamLoopScenarioTracker = ({
    damLoopScenario,
    onStopInterval,
    onStartInterval,
    onUpdateIntervalSettings,
    intervalGDXFiles,
    downloadGdxFile,
    retrieveIntervalLogs,
    onDeleteScenario,
    loading,
    deletionMessage,
}) => {
    const [selectedFiles, setSelectedFiles] = useState({})
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

    const handleChange = (intervalId, value, filename) => {
        const updatedSelectedFiles = { ...selectedFiles, [intervalId]: value }
        setSelectedFiles(updatedSelectedFiles)
        downloadGdxFile(value, filename)
    }

    const areAllIntervalsCancelledOrCompleted = (damLoopScenario) => {
        return damLoopScenario.damLoopIntervals.every(
            (interval) =>
                interval.state === 'CANCELLED' ||
                interval.state === 'COMPLETE' ||
                interval.state === 'FAILED' ||
                interval.state === 'PENDING'
        )
    }

    return (
        <div className="dam-loop-scenario">
            <Grid container>
                <Grid item xs={6}>
                    <h2>{damLoopScenario.name}</h2>
                </Grid>
                <Grid item xs={4}>
                    {damLoopScenario.isDeleting && (
                        <div style={{ margin: '12px 0' }}>
                            <Typography>{`Deletion Status: ${
                                deletionMessage || '...'
                            }`}</Typography>
                        </div>
                    )}
                </Grid>
                <Grid item xs={1}>
                    {damLoopScenario.isDeleting && (
                        <div style={{ margin: '24px 0' }}>
                            <LinearProgress variant="indeterminate" />
                        </div>
                    )}
                </Grid>
                <Grid item xs={1}>
                    {areAllIntervalsCancelledOrCompleted(damLoopScenario) &&
                        !loading && (
                            <Tooltip
                                title={`Delete scenario ${damLoopScenario.name}`}
                            >
                                <IconButton
                                    onClick={() =>
                                        onDeleteScenario(damLoopScenario.id)
                                    }
                                    aria-label="delete"
                                    color="primary"
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        )}
                </Grid>
            </Grid>
            <p>ID: {damLoopScenario.id}</p>
            <p>Pipeline ID: {damLoopScenario.pipelineId}</p>
            <p>
                DAM Pipeline Scenario ID:{' '}
                {damLoopScenario.damPipelineScenarioId}
            </p>

            <h3>Intervals</h3>
            <div>
                {damLoopScenario.damLoopIntervals.map((interval, index) => (
                    <div key={interval.id}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <div>
                                    <strong>Interval ID:</strong>{' '}
                                    {interval.data_extraction_interval_id}
                                </div>
                                <div>
                                    <strong>Round:</strong> {interval.round}
                                </div>
                                <div>
                                    <strong>Run:</strong> {interval.run}
                                </div>
                                <div>
                                    <strong>State:</strong> {interval.state}
                                </div>
                                {interval.preCancelledState && (
                                    <div>
                                        <strong>Pre-Cancelled State:</strong>{' '}
                                        {interval.preCancelledState}
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                    <strong>Max Contingencies:</strong>
                                    {interval.state === 'CANCELLED' ||
                                    interval.state === 'PENDING' ||
                                    interval.state === 'FAILED' ? (
                                        <TextField
                                            type="number"
                                            value={interval.maxContingencies}
                                            onChange={(e) =>
                                                onUpdateIntervalSettings(
                                                    damLoopScenario.id,
                                                    interval.id,
                                                    {
                                                        ...interval,
                                                        maxContingencies:
                                                            e.target.value,
                                                    }
                                                )
                                            }
                                            style={{
                                                marginLeft: '10px',
                                            }}
                                        />
                                    ) : (
                                        interval.maxContingencies
                                    )}
                                </div>
                                <div>
                                    <strong>Max New Lines:</strong>
                                    {interval.state === 'CANCELLED' ||
                                    interval.state === 'PENDING' ||
                                    interval.state === 'FAILED' ? (
                                        <TextField
                                            type="number"
                                            value={interval.maxNewLines}
                                            onChange={(e) =>
                                                onUpdateIntervalSettings(
                                                    damLoopScenario.id,
                                                    interval.id,
                                                    {
                                                        ...interval,
                                                        maxNewLines:
                                                            e.target.value,
                                                    }
                                                )
                                            }
                                            style={{ marginLeft: '33px' }}
                                        />
                                    ) : (
                                        interval.maxNewLines
                                    )}
                                </div>
                                <div>
                                    <strong>Max Rounds:</strong>
                                    {interval.state === 'CANCELLED' ||
                                    interval.state === 'PENDING' ||
                                    interval.state === 'FAILED' ? (
                                        <TextField
                                            type="number"
                                            value={interval.maxRounds}
                                            onChange={(e) =>
                                                onUpdateIntervalSettings(
                                                    damLoopScenario.id,
                                                    interval.id,
                                                    {
                                                        ...interval,
                                                        maxRounds:
                                                            e.target.value,
                                                    }
                                                )
                                            }
                                            style={{ marginLeft: '50px' }}
                                        />
                                    ) : (
                                        interval.maxRounds
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl style={{ width: '75%' }}>
                                    <InputLabel
                                        shrink
                                        id="gdx-file-select-label"
                                    >
                                        Available GDX Files
                                    </InputLabel>
                                    <Select
                                        labelId="gdx-file-select-label"
                                        id="gdx-file-select"
                                        value={selectedFiles[interval.id] || ''}
                                        onChange={(e) =>
                                            handleChange(
                                                interval.id,
                                                e.target.value,
                                                intervalGDXFiles[
                                                    interval.id
                                                ].find(
                                                    (file) =>
                                                        file.s3Path ===
                                                        e.target.value
                                                ).file
                                            )
                                        }
                                        displayEmpty
                                    >
                                        {interval.id in intervalGDXFiles ? (
                                            intervalGDXFiles[interval.id].map(
                                                (file) => (
                                                    <MenuItem
                                                        key={file.s3Path}
                                                        value={file.s3Path}
                                                    >
                                                        {file.file}
                                                    </MenuItem>
                                                )
                                            )
                                        ) : (
                                            <MenuItem value={''}>
                                                <em>None</em>
                                            </MenuItem>
                                        )}
                                    </Select>
                                    <FormHelperText>
                                        Select file to download
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <Grid>
                                    <IconButton
                                        color="primary"
                                        onClick={() => onStopInterval(interval)}
                                        disabled={
                                            interval.state === 'CANCELLED' ||
                                            interval.state === 'PENDING' ||
                                            interval.state === 'FAILED' ||
                                            interval.state === 'COMPLETE' ||
                                            loading ||
                                            damLoopScenario.isDeleting
                                        }
                                    >
                                        <StopIcon />
                                    </IconButton>
                                    <IconButton
                                        color="primary"
                                        onClick={() =>
                                            onStartInterval(interval)
                                        }
                                        disabled={
                                            (interval.state !== 'CANCELLED' &&
                                                interval.state !== 'PENDING' &&
                                                interval.state !== 'COMPLETE' &&
                                                interval.state !== 'FAILED') ||
                                            loading ||
                                            damLoopScenario.isDeleting
                                        }
                                    >
                                        <PlayArrowIcon />
                                    </IconButton>
                                    <IconButton
                                        color="primary"
                                        onClick={() =>
                                            retrieveIntervalLogs(interval.id)
                                        }
                                        disabled={loading}
                                    >
                                        <NotesIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {interval.state !== 'CANCELLED' &&
                            interval.state !== 'PENDING' &&
                            interval.state !== 'COMPLETE' &&
                            interval.state !== 'FAILED' && (
                                <div style={{ margin: '8px 0' }}>
                                    <LinearProgress variant="indeterminate" />
                                </div>
                            )}
                        {interval.state === 'CANCELLED' ||
                            interval.state === 'COMPLETE' ||
                            interval.state === 'PENDING' ||
                            (interval.state === 'FAILED' && (
                                <div style={{ margin: '8px 0' }}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={0}
                                    />
                                </div>
                            ))}
                    </div>
                ))}
            </div>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showAdvancedOptions}
                                onChange={() => {
                                    setShowAdvancedOptions(!showAdvancedOptions)
                                }}
                                value={showAdvancedOptions}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography>Show Advanced Settings</Typography>
                </Grid>
                {showAdvancedOptions && (
                    <>
                        <Grid item>
                            <Typography>
                                Advanced Configuration (YAML)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                height="350px"
                                language="yaml"
                                theme="vs-light"
                                value={damLoopScenario.advancedSettings || ''}
                                options={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Pre-Process Input Hook</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                height="250px"
                                language="python"
                                theme="vs-light"
                                value={
                                    damLoopScenario.preProcessInputHook || ''
                                }
                                options={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Pre-Process Model Hook</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                height="250px"
                                language="python"
                                theme="vs-light"
                                value={
                                    damLoopScenario.preProcessModelHook || ''
                                }
                                options={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Post-Process Model Hook</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                height="250px"
                                language="python"
                                theme="vs-light"
                                value={
                                    damLoopScenario.postProcessModelHook || ''
                                }
                                options={{ readOnly: true }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    )
}

export default DamLoopScenarioTracker
