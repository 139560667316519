import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { useEffect, useState } from 'react'

const PipelineSettings = (props) => {
    const { runSettings, pipelineName, classes } = props

    const [flattenedRunSettings, setFlattenedRunSettings] = useState({})

    useEffect(() => {
        function flattenObj(obj) {
            let flat = {}
            for (let i in obj) {
                if (typeof obj[i] == 'object') {
                    let flatObj = flattenObj(obj[i])
                    for (let x in flatObj) {
                        flat[i + '_' + x] = flatObj[x]
                    }
                } else {
                    flat[i] = obj[i]
                }
            }
            return flat
        }
        setFlattenedRunSettings(flattenObj(runSettings))
    }, [runSettings])

    function snakeToTitleCase(snakeCaseString) {
        const titleCase = snakeCaseString.replace(
            /^_*(.)|_+(.)/g,
            (snakeCaseString, c, d) =>
                c ? c.toUpperCase() : ' ' + d.toUpperCase()
        )
        return titleCase
    }

    return (
        <Grid item xs={12} className={classes.accordionGrid}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>
                        {snakeToTitleCase(pipelineName)} Settings
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        className={classes.accordionContentGrid}
                    >
                        {Object.keys(flattenedRunSettings).map((key, index) => {
                            return (
                                <Grid item xs={3} key={index}>
                                    <h4>{snakeToTitleCase(key)}:</h4>
                                    <Typography>
                                        {flattenedRunSettings[key] !== undefined
                                            ? flattenedRunSettings[
                                                  key
                                              ].toString()
                                            : ''}
                                    </Typography>
                                </Grid>
                            )
                        })}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default PipelineSettings
