import { useEffect, useState } from 'react'
import { get } from 'lodash'
import { Grid } from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    gridItem: {
        padding: '.75rem 0',
        width: '100%',
    },
    input: {
        display: 'flex',
    },
}))

const IntervalSplitDate = (props) => {
    const { onChange, formContext } = props
    const [intervals, setIntervals] = useState(0)
    const [splitDates, setSplitDates] = useState([])
    const [initialDateCPT, setInitialDateCPT] = useState(null)
    const [finalDateCPT, setFinalDateCPT] = useState(null)
    const [dataTransformationPipelineId, setDataTransformationPipelineId] =
        useState(null)

    const classes = useStyles()

    useEffect(() => {
        async function fetchIntervalCount() {
            let newIntervalCount = get(formContext, 'intervals', 0)
            if (newIntervalCount < intervals) {
                setSplitDates([])
                onChange([])
            }
            setIntervals(newIntervalCount)

            let dataTransformationPipeline = get(
                formContext,
                'dataTransformationPipelineId',
                null
            )

            if (dataTransformationPipeline !== dataTransformationPipelineId) {
                setDataTransformationPipelineId(dataTransformationPipeline)
                if (dataTransformationPipeline !== null)
                    await getDataTransformationDates(dataTransformationPipeline)
            }
        }
        fetchIntervalCount()
    })

    const updateSplitDates = async (date, index) => {
        let newArr = [...splitDates]
        if (date === null || date === undefined) newArr[index] = null
        else newArr[index] = date
        setSplitDates(newArr)
        onChange(newArr.map((date) => date.startOf('day').format('YYYY-MM-DD')))
    }

    const getDataTransformationDates = async (id) => {
        try {
            let res = await axios.get(
                `/pipeline/${id}?pipeline_name=data_transformation_pipeline`
            )
            setInitialDateCPT(
                moment(res.data.pipeline.run_settings.initial_date_cpt)
            )
            setFinalDateCPT(
                moment(res.data.pipeline.run_settings.final_date_cpt)
            )
        } catch (error) {
            if (error.response.status === 500) {
                console.error('Error searching pipeline dates')
            }
        }
    }

    const filterDatesNotIncluded = (date) => {
        if (date >= initialDateCPT)
            if (date <= finalDateCPT) return false
            else return true
        return true
    }

    const getSplitDates = () => {
        let content = []
        for (let i = 0; i < intervals - 1; i++) {
            content.push(
                <li key={i}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            disabled={
                                initialDateCPT === null || finalDateCPT === null
                            }
                            className={classes.input}
                            variant="inline"
                            format="MM/DD/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={'Split Date ' + (i + 1)}
                            value={splitDates[i] || null}
                            onChange={(date) => updateSplitDates(date, i)}
                            shouldDisableDate={filterDatesNotIncluded}
                            InputProps={{ readOnly: true }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </li>
            )
        }
        return content
    }
    return (
        <Grid item xs={12} className={classes.gridItem}>
            <ul>{getSplitDates(intervals)}</ul>
        </Grid>
    )
}
export default IntervalSplitDate
