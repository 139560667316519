import { useEffect, useState, useCallback } from 'react'
import { getDAMAuctionMonthAndYear } from '../../../api'
import {
    FormControl,
    NativeSelect,
    InputLabel,
    Tooltip,
} from '@material-ui/core'

const PortfolioStatsAuctionMonthAndYearWidget = (props) => {
    const { value, onChange, formContext, id } = props
    const [dates, setDates] = useState([])

    // To be used in the array
    const [auctionTypeState, setAuctionTypeState] = useState(null)

    const decodeIdInArray = (tentativeId, monthsToRun) => {
        // Get the current id of formContext?.monthsToRun (an array)
        // if the format of the id is wrong or the element does not exist in the array, returns null
        try {
            // pattern of tentativeId: "root_monthsToRun_1_auctionMonthAndYear"
            let idInArray = parseInt(
                tentativeId
                    .split('monthsToRun_')[1]
                    .replace('_auctionMonthAndYear', '')
            )
            if (
                monthsToRun[idInArray] !== null &&
                monthsToRun[idInArray] !== undefined
            ) {
                return idInArray
            }
        } catch (error) {}
        return null
    }

    const getNullIfUndefined = (valueIn) => {
        if (valueIn === undefined) {
            return null
        }
        return valueIn
    }

    const handleChange = useCallback(
        (month) => {
            onChange(month)
        },
        [onChange]
    )

    useEffect(() => {
        const asyncGetDAMAuctionMonthAndYear = async () => {
            let monthsToRun = formContext?.monthsToRun
            let idInArray = decodeIdInArray(id, monthsToRun)

            if (idInArray !== null) {
                // Detect changes
                let currentAuctionType = getNullIfUndefined(
                    monthsToRun[idInArray].auctionType
                )
                if (currentAuctionType !== auctionTypeState) {
                    setAuctionTypeState(currentAuctionType)

                    if (
                        currentAuctionType === 'Annual' ||
                        currentAuctionType === 'Monthly'
                    ) {
                        // If something should be loaded
                        let currentAuctionSequence = getNullIfUndefined(
                            monthsToRun[idInArray].auctionSequence
                        )
                        const res = await getDAMAuctionMonthAndYear(
                            currentAuctionSequence
                        )
                        if (res.error === null) {
                            setDates(res.data)
                            handleChange(undefined)
                        } else {
                            setDates([])
                            handleChange(undefined)
                        }
                    } else {
                        setDates([])
                        handleChange(undefined)
                    }
                }
            }
        }

        asyncGetDAMAuctionMonthAndYear()
    }, [formContext.auctionSequence, handleChange])

    const onSelected = (event) => {
        onChange(event.target.value)
    }

    return (
        <Tooltip title="Distinct list of auction month / auction year in crr annual auction model data">
            <FormControl>
                <InputLabel shrink={true}>
                    Auction Month / Auction Year
                </InputLabel>
                <NativeSelect onChange={onSelected} value={value}>
                    {dates.map((option) => {
                        return (
                            <option
                                key={option.month + '/' + option.year}
                                value={option.month + '/' + option.year}
                            >
                                {option.month + '/' + option.year}
                            </option>
                        )
                    })}
                </NativeSelect>
            </FormControl>
        </Tooltip>
    )
}
export default PortfolioStatsAuctionMonthAndYearWidget
