import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
    Button,
    CircularProgress,
    Tooltip,
} from '@material-ui/core'

import ConfirmationDialog from './ConfirmationDialog'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { Component } from 'react'
import {
    pipelineUploadFile,
    getPipelineUploadFiles,
    deleteUploadedFile,
} from '../../api'
import { SocketContext } from '../../WebSockets/SocketContext'

// Helper function to delay execution
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
const MAX_RETRY_COUNT = 3

class FileUploadWidget extends Component {
    static contextType = SocketContext
    _isMounted = false

    constructor(props) {
        super(props)

        this.state = {
            pipelineUploadFiles: [],
            stagedFiles: [],
            loading: true,
            pipelineUploadFileToBeDeleted: null,
        }
        this.handleFileInputChange = this.handleFileInputChange.bind(this)
        this.unstageFile = this.unstageFile.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
        this.handleDeleteUploadedFile = this.handleDeleteUploadedFile.bind(this)
        this.handlePipelineUploadFileState =
            this.handlePipelineUploadFileState.bind(this)
        this.handleWebSocket = this.handleWebSocket.bind(this)
    }

    async componentDidMount() {
        this._isMounted = true
        await this.handleWebSocket()
        this.handlePipelineUploadFileState()
    }

    componentWillUnmount() {
        this._isMounted = false
        const socket = this.props.socket
        if (socket) {
            socket.off('upload_status')
        }
    }

    async handleWebSocket() {
        const socket = this.props.socket
        if (socket) {
            // Listen for data from the server
            socket.onMessage('upload_status', () => {
                this.handlePipelineUploadFileState()
            })
        }
    }

    async handlePipelineUploadFileState() {
        if (!this._isMounted) return

        this.setState({ loading: true })
        const res = await getPipelineUploadFiles(
            this.props.pipelineId,
            this.props.pipelineName,
            this.props.fileUploadType
        )
        if (this._isMounted) {
            if (res.error) {
                // TODO: Delete from staged and immediately move to failed
            } else if (res.data) {
                const pipelineUploadFilesList = res.data
                this.setState({ pipelineUploadFiles: pipelineUploadFilesList })
            }
            this.setState({ loading: false })
        }
    }

    handleFileInputChange = (event) => {
        const files = []
        Array.from(event.target.files).forEach((file) => {
            file.filename = file.name
            files.push(file)
        })
        this.setState({ stagedFiles: files })
    }

    unstageFile = (index) => {
        let unstagedFileToRemove = this.state.stagedFiles.splice(index, 1)
        this.setState({
            stagedFiles: this.state.stagedFiles.filter(
                (f) => f.filename !== unstagedFileToRemove.filename
            ),
        })
    }

    uploadFiles = async (event) => {
        const stagedFilesTemp = [...this.state.stagedFiles]
        for (let idx = stagedFilesTemp.length - 1; idx >= 0; idx--) {
            let formData = new FormData()
            formData.append('file_upload_type', this.props.fileUploadType)
            formData.append('file_name', stagedFilesTemp[idx].filename)
            formData.append('file_data', stagedFilesTemp[idx])
            this.setState({ loading: true })
            let retryCount = 0
            let retryDelay = 1000 // Initial delay for retry (in milliseconds)

            while (retryCount < MAX_RETRY_COUNT) {
                try {
                    let res = await pipelineUploadFile(
                        this.props.pipelineId,
                        this.props.pipelineName,
                        formData
                    )
                    this.setState({ loading: false })

                    if (res.status === 200) {
                        this.unstageFile(idx)
                        const pipelineUploadFilesList = [
                            ...this.state.pipelineUploadFiles,
                        ]
                        pipelineUploadFilesList.push(res.data)
                        this.setState({
                            pipelineUploadFiles: pipelineUploadFilesList,
                        })
                        break // Break the retry loop on success
                    } else if (res.status === 400) {
                        const userErrorFile = stagedFilesTemp[idx]
                        this.unstageFile(idx)
                        const pipelineUploadFilesList = [
                            ...this.state.pipelineUploadFiles,
                        ]
                        userErrorFile.status = 3
                        userErrorFile.job_notes = 'File already exists!'
                        pipelineUploadFilesList.push(userErrorFile)
                        this.setState({
                            pipelineUploadFiles: pipelineUploadFilesList,
                        })
                        break
                    } else {
                        // TODO: Alert the user that this upload failed.
                        const jitter = Math.random() * 1000 // Random jitter (in milliseconds)
                        await delay(retryDelay + jitter)
                        retryDelay *= 2 // Exponential backoff
                        retryCount++
                    }
                } catch (error) {
                    // Retry on failure
                    const jitter = Math.random() * 1000 // Random jitter (in milliseconds)
                    await delay(retryDelay + jitter)
                    retryDelay *= 2 // Exponential backoff
                    retryCount++
                }
            }
        }
    }

    handleDelete = async (file) => {
        this.setState({ pipelineUploadFileToBeDeleted: file })
    }

    handleDeleteUploadedFile = async (fileToDeleteId) => {
        this.setState({ loading: true })
        const res = await deleteUploadedFile(fileToDeleteId)

        if (res.error) {
            // TODO: How to alert that we failed to delete
        } else {
            this.setState({
                pipelineUploadFiles: this.state.pipelineUploadFiles.filter(
                    (obj) => obj.id !== fileToDeleteId
                ),
            })
        }
        this.setState({ loading: false })
    }

    handleClose = async (newValue) => {
        if (newValue) {
            const file = this.state.pipelineUploadFileToBeDeleted
            this.setState({ pipelineUploadFileToBeDeleted: null })
            if (file.id === undefined) {
                this.setState({
                    pipelineUploadFiles: this.state.pipelineUploadFiles.filter(
                        (obj) => obj !== file
                    ),
                })
            } else await this.handleDeleteUploadedFile(file.id)
        } else {
            this.setState({ pipelineUploadFileToBeDeleted: null })
        }
    }

    render() {
        const classes = this.props.classes
        return (
            <Grid item xs={12}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Typography className={classes.heading}>
                                {this.props.fileUploadHeader}
                            </Typography>
                            {(this.state.pipelineUploadFiles.filter(
                                (f) => f.status === 1
                            ).length > 0 ||
                                this.state.loading) && (
                                <CircularProgress color="primary" size={25} />
                            )}
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            className={classes.accordionContentGrid}
                        >
                            <Grid item>
                                Staged Files
                                {this.state.stagedFiles.length > 0 && (
                                    <Typography style={{ color: 'red' }}>
                                        Please do not leave page before staged
                                        files have moved into uploading state or
                                        you will lose all progress!
                                    </Typography>
                                )}
                            </Grid>
                            <hr style={{ width: '100%' }} />

                            {this.state.stagedFiles.length > 0 && (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    {this.state.stagedFiles.map(
                                        (file, index) => (
                                            <Grid
                                                item
                                                xs={2}
                                                key={index}
                                                className={classes.gridItem}
                                            >
                                                <Chip
                                                    label={file.filename}
                                                    onDelete={(e) => {
                                                        this.unstageFile(index)
                                                    }}
                                                    variant="outlined"
                                                    disabled={
                                                        this.state.pipelineUploadFiles.filter(
                                                            (f) =>
                                                                f.status === 1
                                                        ).length > 0
                                                    }
                                                />
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            )}

                            <Grid item>Uploading Files</Grid>
                            <hr style={{ width: '100%' }} />
                            {this.state.pipelineUploadFiles.filter(
                                (f) => f.status === 1
                            ).length > 0 && (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    {this.state.pipelineUploadFiles
                                        .filter((f) => f.status === 1)
                                        .map((file, index) => (
                                            <Grid
                                                item
                                                xs={'auto'}
                                                key={index}
                                                className={classes.gridItem}
                                            >
                                                <Tooltip
                                                    title={
                                                        <span
                                                            style={{
                                                                whiteSpace:
                                                                    'pre-line',
                                                            }}
                                                        >
                                                            {file.job_notes ||
                                                                ''}
                                                        </span>
                                                    }
                                                >
                                                    <Chip
                                                        label={file.filename}
                                                        color="primary"
                                                        variant="outlined"
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        ))}
                                </Grid>
                            )}

                            <Grid item>Completed Files</Grid>
                            <hr style={{ width: '100%' }} />
                            {this.state.pipelineUploadFiles.filter(
                                (f) => f.status === 2
                            ).length > 0 && (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    {this.state.pipelineUploadFiles
                                        .filter((f) => f.status === 2)
                                        .map((file, index) => (
                                            <Grid
                                                item
                                                xs={'auto'}
                                                key={index}
                                                className={classes.gridItem}
                                            >
                                                <Tooltip
                                                    title={
                                                        <span
                                                            style={{
                                                                whiteSpace:
                                                                    'pre-line',
                                                            }}
                                                        >
                                                            {file.job_notes ||
                                                                ''}
                                                        </span>
                                                    }
                                                >
                                                    <Chip
                                                        label={file.filename}
                                                        color="primary"
                                                        onDelete={async (e) => {
                                                            this.handleDelete(
                                                                file
                                                            )
                                                        }}
                                                        disabled={
                                                            file ===
                                                            this.state
                                                                .pipelineUploadFileToBeDeleted
                                                        }
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        ))}
                                </Grid>
                            )}

                            <Grid item>Failed Files</Grid>
                            <hr style={{ width: '100%' }} />
                            {this.state.pipelineUploadFiles.filter(
                                (f) => f.status === 3
                            ).length > 0 && (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    {this.state.pipelineUploadFiles
                                        .filter((f) => f.status === 3)
                                        .map((file, index) => (
                                            <Grid
                                                item
                                                xs={'auto'}
                                                key={index}
                                                className={classes.gridItem}
                                            >
                                                <Tooltip
                                                    title={file.job_notes || ''}
                                                >
                                                    <Chip
                                                        label={file.filename}
                                                        color="secondary"
                                                        onDelete={async (e) => {
                                                            this.handleDelete(
                                                                file
                                                            )
                                                        }}
                                                        disabled={
                                                            file ===
                                                            this.state
                                                                .pipelineUploadFileToBeDeleted
                                                        }
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        ))}
                                </Grid>
                            )}

                            <Grid
                                container
                                direction="row"
                                alignContent="center"
                                justifyContent="center"
                            >
                                <Grid item className={classes.gridButton}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        disabled={
                                            this.state.pipelineUploadFiles.filter(
                                                (f) => f.status === 1
                                            ).length > 0 || this.state.loading
                                        }
                                    >
                                        Stage Files
                                        <input
                                            onChange={
                                                this.handleFileInputChange
                                            }
                                            type="file"
                                            accept={
                                                this.props.acceptedFileTypes
                                            }
                                            hidden
                                            multiple
                                        />
                                    </Button>
                                </Grid>
                                <Grid item className={classes.gridButton}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="secondary"
                                        onClick={this.uploadFiles}
                                        disabled={
                                            this.state.stagedFiles.length <=
                                                0 ||
                                            this.state.loading ||
                                            this.state.pipelineUploadFiles.filter(
                                                (f) => f.status === 1
                                            ).length > 0
                                        }
                                    >
                                        Upload
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <ConfirmationDialog
                    classes={{
                        paper: classes.paper,
                    }}
                    keepMounted
                    title={`Confirm deletion`}
                    open={this.state.pipelineUploadFileToBeDeleted !== null}
                    onClose={this.handleClose}
                    dialogContent={`Are you sure you want to delete this ${this.state.pipelineUploadFileToBeDeleted?.filename} file?`}
                />
            </Grid>
        )
    }
}

export default FileUploadWidget
