import { useState, useEffect } from 'react'
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Button,
    TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import axios from 'axios'
import { convertToLocalTime } from '../../Utils/TimeUtil'

const PipelineNotesForm = ({ pipelineId, pipelineType, classes }) => {
    const [pipelineNotes, setPipelineNotes] = useState([])
    const [selectedNote, setSelectedNote] = useState(null)
    const [modifiedNotes, setModifiedNotes] = useState([])
    const [isMounted, setIsMounted] = useState(true) // Track component mount status

    useEffect(() => {
        fetchPipelineNotes()

        return () => {
            setIsMounted(false) // Set isMounted to false when component unmounts
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchPipelineNotes = async () => {
        try {
            const response = await axios.get('/pipeline/notes', {
                params: {
                    pipeline_id: pipelineId,
                    pipeline_type: pipelineType,
                },
            })
            if (isMounted) {
                setPipelineNotes(response.data)
            }
        } catch (error) {
            console.error('Error fetching pipeline notes:', error)
        }
    }

    const handleNoteClick = (note) => {
        setSelectedNote(note)
    }

    const handleNoteChange = (event) => {
        const updatedNote = { ...selectedNote }
        updatedNote.note = event.target.value
        setSelectedNote(updatedNote)
        if (selectedNote && !isNoteModified(selectedNote.id)) {
            setModifiedNotes([...modifiedNotes, selectedNote.id])
        }
    }

    const handleSaveClick = async () => {
        try {
            const modifiedNotesToSave = modifiedNotes.filter((noteId) => {
                return pipelineNotes.some((note) => note.id === noteId)
            })

            const savePromises = modifiedNotesToSave.map(async (noteId) => {
                const note = pipelineNotes.find((note) => note.id === noteId)
                const updatedNote = {
                    note: selectedNote.note,
                    pipeline_id: pipelineId,
                    pipeline_type: pipelineType,
                }

                await axios.put(`/pipeline/notes/${note.id}`, updatedNote)
            })

            // Handle new note without ID
            if (selectedNote && !selectedNote.id && selectedNote.note) {
                const newNote = {
                    note: selectedNote.note,
                    pipeline_id: pipelineId,
                    pipeline_type: pipelineType,
                }

                await axios.post('/pipeline/notes', newNote)
            }

            await Promise.all(savePromises)

            setSelectedNote(null)
            setModifiedNotes([])
            fetchPipelineNotes()
        } catch (error) {
            console.error('Error saving notes:', error)
        }
    }

    const handleDeleteClick = async (noteId) => {
        try {
            await axios.delete(`/pipeline/notes/${noteId}`)
            if (selectedNote && selectedNote.id === noteId) {
                setSelectedNote(null)
                setModifiedNotes([])
            }
            fetchPipelineNotes()
        } catch (error) {
            console.error('Error deleting note:', error)
        }
    }

    const isNoteModified = (noteId) => {
        return modifiedNotes.includes(noteId)
    }

    return (
        <Grid item xs={12} className={classes.accordionGrid}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Notes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper
                                elevation={3}
                                style={{
                                    padding: '1rem',
                                    marginBottom: '1rem',
                                }}
                            >
                                <TextField
                                    multiline
                                    minRows={5}
                                    fullWidth
                                    value={
                                        selectedNote ? selectedNote.note : ''
                                    }
                                    onChange={handleNoteChange}
                                    style={{ marginBottom: '1rem' }}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                    onClick={handleSaveClick}
                                    disabled={
                                        !selectedNote ||
                                        (!selectedNote.id && !selectedNote.note)
                                    }
                                >
                                    Save Note(s)
                                </Button>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                {pipelineNotes.map((note) => (
                                    <ListItem
                                        key={note.id}
                                        button
                                        onClick={() => handleNoteClick(note)}
                                    >
                                        <ListItemText
                                            primary={note.note}
                                            secondary={`${convertToLocalTime(
                                                note.timestamp
                                            )} - ${note.author}`}
                                        />
                                        {note.id && (
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() =>
                                                        handleDeleteClick(
                                                            note.id
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default PipelineNotesForm
