import { TextField } from '@material-ui/core'
import { useEffect } from 'react'

const DAMPostProcessingAuctionTypeWidget = (props) => {
    const { value, onChange, formContext } = props

    useEffect(() => {
        let auctionSequence = formContext?.auctionSequence

        if (auctionSequence !== null && auctionSequence !== undefined) {
            const auctionType = auctionSequence > 0 ? 'Annual' : 'Monthly'
            onChange(auctionType)
        }
    }, [formContext?.auctionSequence, onChange])

    return (
        <TextField
            variant="outlined"
            label="Auction Type"
            value={value || ''}
            disabled
        />
    )
}
export default DAMPostProcessingAuctionTypeWidget
