import { Grid, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import { convertToLocalTime } from '../../Utils/TimeUtil'

const PipelineAlert = (props) => {
    const { alert } = props

    const getAlertTextColor = (alertLevel) => {
        switch (alertLevel) {
            case 'Warn':
                return '#FFA500'
            case 'Error':
                return '#FF0000'
            default:
                return '#000000'
        }
    }

    const getAlertIcon = (alertLevel) => {
        switch (alertLevel) {
            case 'Warn':
                return (
                    <WarningIcon
                        style={{ color: getAlertTextColor(alertLevel) }}
                    />
                )
            case 'Error':
                return (
                    <ErrorIcon
                        style={{ color: getAlertTextColor(alertLevel) }}
                    />
                )
            default:
                return (
                    <InfoIcon
                        style={{ color: getAlertTextColor(alertLevel) }}
                    />
                )
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Grid item xs={1}>
                {getAlertIcon(alert.alert_level)}
            </Grid>
            <Grid item xs={3}>
                <Typography variant="subtitle2">
                    {' '}
                    {convertToLocalTime(alert.timestamp)}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="subtitle2">
                    {alert.alert_level || alert.alertLevel}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography
                    style={{
                        color: getAlertTextColor(
                            alert.alert_level || alert.alertLevel
                        ),
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    {alert.alert?.replace(/\\n/g, '\n').replace(/\\t/g, '\t') ||
                        alert?.message
                            .replace(/\\n/g, '\n')
                            .replace(/\\t/g, '\t')}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default PipelineAlert
