import Layout from '../../Layout'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    Snackbar,
    CircularProgress,
    Chip,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import MuiAlert from '@material-ui/lab/Alert'
import { SearchableSelect } from '../../SearchableSelect/SearchableSelect'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PaginatedTable from '../Components/TablePagination'
import { get } from 'lodash'

const useStyles = makeStyles((theme) => ({
    uploadFileInput: {
        float: 'right',
        textTransform: 'unset',
    },
    gridItemSearch: {
        width: '100%',
    },
    gridContainer: {
        display: 'Grid',
    },
    gridItem: {
        padding: '.75rem',
        width: '100%',
        margin: 'auto',
    },
    accordionContainer: {
        width: '100%', // Set width to 100%
        overflow: 'auto',
        '& .MuiAccordion-root': {
            width: '100%', // Ensure that the Accordion itself is also 100% width
        },
        '& .MuiAccordionDetails-root': {
            padding: 'inherit',
            overflowX: 'auto',
        },
    },
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    sectionHeader: {
        padding: '1.2rem 0',
        display: 'flex',
        flexWrap: 'nowrap',
        position: 'static',
        margin: 'auto',
    },
    columnChip: {
        padding: '1rem',
        margin: '.25rem',
    },
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const InfoPanel = ({ children, value, index }) => (
    <div>
        {value === index && (
            <Typography component={'span'}>{children}</Typography>
        )}
    </div>
)

const LPStats = () => {
    const [alertOpen, setAlertOpen] = useState(null)
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(null)
    const [lps, setLps] = useState([])
    const [selectedValue, setSelectedValue] = useState(null)
    const [selectedInfo, setSelectedInfo] = useState({})
    const classes = useStyles()

    useEffect(() => {
        const getSuggestions = async () => {
            const res = await axios.get(`/lp_stats/list`)
            if (res.status !== 200) {
                setLps([])
            } else {
                if (res.data.length === 0) {
                    showAlert('No LP classes found')
                } else {
                    setLps(res.data)
                }
            }
        }
        getSuggestions()
    }, [])

    const handleChange = async (event) => {
        setLoading(true)
        const index = event.target.value
        if (index === undefined) {
            setSelectedValue(null)
        } else {
            setSelectedValue(Number(lps[index].id))
            const results = await axios.get(`/lp_stats/${lps[index].name}`)
            setSelectedInfo(results.data)
        }
        setLoading(false)
    }

    const showAlert = (message) => {
        setAlertOpen(true)
        setAlertMessage(message)
    }

    return (
        <Layout>
            <div style={{ marginBottom: '2rem' }}>
                <Typography component="div" className={classes.sectionHeader}>
                    <Typography variant="h5" gutterBottom>
                        LP Stats
                    </Typography>
                </Typography>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={6} className={classes.gridItemSearch}>
                        <SearchableSelect
                            key={selectedValue}
                            label="Select LP"
                            value={selectedValue}
                            onChange={handleChange}
                            options={lps}
                            keyPropFn={(option) => option.id}
                            valuePropFn={(option) =>
                                option.architecture_code +
                                ' - ' +
                                option.friendly_name
                            }
                            showAll
                            removeSelectionText
                            alwaysFocusTextFieldOnEnter
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        className={`${classes.gridItem} ${classes.accordionContainer}`}
                    >
                        <InfoPanel
                            value={selectedValue}
                            index={selectedValue}
                            key={selectedValue}
                        ></InfoPanel>
                        {selectedInfo !== null &&
                            selectedInfo.stats_data !== undefined && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        className={`${classes.gridItem} ${classes.accordionContainer}`}
                                    >
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography
                                                    className={classes.heading}
                                                >
                                                    Columns
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.gridItem}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={
                                                            classes.gridItem
                                                        }
                                                    >
                                                        <Typography variant="subtitle1">
                                                            Report Date:{' '}
                                                            {get(
                                                                selectedInfo,
                                                                'report_date'
                                                            )}
                                                        </Typography>
                                                        <hr />
                                                        <Typography variant="subtitle1">
                                                            LP Table Columns:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={
                                                            classes.gridItem
                                                        }
                                                    >
                                                        {get(
                                                            selectedInfo,
                                                            'stats_data.columns',
                                                            []
                                                        ).map(
                                                            (element, idx) => (
                                                                <Chip
                                                                    color="primary"
                                                                    className={
                                                                        classes.columnChip
                                                                    }
                                                                    key={idx}
                                                                    label={
                                                                        element
                                                                    }
                                                                ></Chip>
                                                            )
                                                        )}
                                                        <hr />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={`${classes.gridItem} ${classes.accordionContainer}`}
                                    >
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography
                                                    className={classes.heading}
                                                >
                                                    Run Stats
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <PaginatedTable
                                                    data={
                                                        selectedInfo.stats_data
                                                    }
                                                    rowsPerPage={10}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </>
                            )}
                        {selectedInfo !== null &&
                            selectedInfo.compute_data !== undefined && (
                                <Grid item xs={12} className={classes.gridItem}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                                className={classes.heading}
                                            >
                                                Numeric Stats
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <PaginatedTable
                                                data={selectedInfo.compute_data}
                                                rowsPerPage={10}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )}
                        {selectedInfo !== null &&
                            selectedInfo.custom_stat_queries_data !==
                                undefined &&
                            selectedInfo.custom_stat_queries_data.map(
                                (item, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        xs={12}
                                        className={classes.gridItem}
                                    >
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`panel${
                                                    index + 1
                                                }-content`}
                                                id={`panel${index + 1}-header`}
                                            >
                                                <Typography
                                                    className={classes.heading}
                                                >
                                                    {item.name}
                                                </Typography>
                                            </AccordionSummary>
                                            <Typography
                                                variant="subtitle1"
                                                className={classes.heading}
                                                style={{
                                                    paddingLeft: '1rem',
                                                }}
                                            >
                                                Description: {item.description}
                                            </Typography>
                                            <hr />
                                            <AccordionDetails>
                                                <PaginatedTable
                                                    data={item}
                                                    rowsPerPage={10}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )
                            )}
                    </Grid>

                    {loading && (
                        <div className={classes.loadingIndicator}>
                            <CircularProgress color="primary" size={75} />
                        </div>
                    )}
                    {selectedValue !== null &&
                        selectedInfo.stats_data === undefined &&
                        !loading && (
                            <Typography
                                component="div"
                                className={classes.sectionHeader}
                            >
                                <Typography variant="h5" gutterBottom>
                                    {'No stats found for LP'}
                                </Typography>
                            </Typography>
                        )}
                    {selectedValue === null && !loading && (
                        <Typography
                            component="div"
                            className={classes.sectionHeader}
                        >
                            <Typography variant="h5" gutterBottom>
                                {'No LP selected yet...'}
                            </Typography>
                        </Typography>
                    )}
                </Grid>
            </div>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
            >
                <Alert onClose={() => setAlertOpen(false)} severity="error">
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Layout>
    )
}
export default LPStats
