import { Component } from 'react'
import { withStyles, CircularProgress } from '@material-ui/core'
import { withRouter } from 'react-router'
import axios from 'axios'
import CytoscapeComponent from 'react-cytoscapejs'
import klay from 'cytoscape-klay'
import Cytoscape from 'cytoscape'
import Layout from '../Layout/index'

Cytoscape.use(klay)

const styles = (theme) => ({
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    paper: {
        maxHeight: 435,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    cytoscape: {
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
    graphContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'block',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        justifyContent: 'center',
    },
    sideBar: {
        display: 'flex',
        flexDirection: 'row',
        maxHeight: '300px',
        backgroundColor: '#F5C4C4', // padding: "16px",
        fontFamily: theme.typography.fontFamily,
        position: 'absolute',
        bottom: '0px',
        top: 'auto',
        maxWidth: '95%',
        width: 'fit-content',
        height: 'auto',
        borderRadius: '10px',
        // justifyContent: "space-around"
    },
    body: {
        margin: 0,
    },
    sideBarColumn: {
        overflowY: 'auto',
        flex: 1,
        padding: '16px',
        maxWidth: 'fit-content',
        whiteSpace: 'pre-wrap',
        '.span': {
            margin: 0,
        },
    },
    codeFont: {
        fontFamily: 'monospace',
    },
})

const cytoscapeStylesheet = [
    {
        selector: 'node',
        style: {
            content: 'data(name)',
            'text-background-opacity': 1,
            color: '#fff',
            'text-background-color': '#888',
            'text-background-shape': 'roundrectangle',
            'text-border-color': '#000',
            'text-border-width': 1,
            'text-border-opacity': 1,
        },
    },
    {
        selector: 'edge',
        style: {
            width: 3,
            'target-arrow-shape': 'triangle',
        },
    },
]

class PipelineGraph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            data: null,
            selectedData: null,
        }
        this.onSelect = this.onSelect.bind(this)
    }

    async componentDidMount() {
        await this.updateGraph()
    }

    async updateGraph() {
        const results = await axios.get(
            `/pipeline/get_graph_data?pipeline_name=${this.props.pipeline_name}`
        )

        this.setState({
            loading: false,
            data: results.data,
        })
    }

    onSelect(event) {
        this.setState({
            selectedData: event.target._private.data,
        })
    }

    render() {
        const { classes } = this.props

        return (
            <Layout>
                <div className={classes.container}>
                    <div className={classes.graphContainer}>
                        {this.state.data && (
                            <CytoscapeComponent
                                elements={this.state.data.graph_data}
                                className={classes.cytoscape}
                                layout={{
                                    name: 'klay',
                                    nodeDimensionsIncludeLabels: true,
                                }}
                                stylesheet={cytoscapeStylesheet}
                                zoomingEnabled={true}
                                userZoomingEnabled={true}
                                panningEnabled={true}
                                userPanningEnabled={true}
                                maxZoom={3}
                                minZoom={0.7}
                                cy={(cy) => {
                                    cy.on('click', this.onSelect)
                                }}
                            />
                        )}

                        {this.state.loading && (
                            <div className={classes.loadingIndicator}>
                                <CircularProgress color="primary" size={75} />
                            </div>
                        )}
                    </div>
                    {this.state.data && (
                        <SideBar
                            data={this.state.data}
                            selectedData={this.state.selectedData}
                            classes={classes}
                        />
                    )}
                </div>
            </Layout>
        )
    }
}

class SideBar extends Component {
    render() {
        const { selectedData, data, classes } = this.props
        return (
            <div className={classes.sideBar}>
                {selectedData && selectedData.task_name ? (
                    <>
                        <div className={classes.sideBarColumn}>
                            <span>
                                <strong>Task name:</strong>
                                <br /> {selectedData.task_name}
                                <br />
                                <strong>Architecture code:</strong>{' '}
                                {selectedData.architecture_code}
                                <br />
                                <strong>Analyst:</strong> <br />
                                {selectedData.analyst}
                                <br />
                                <strong>Developer:</strong>
                                <br />
                                {selectedData.developer}
                                <br />
                                <strong>Version: </strong>{' '}
                                {selectedData.version}
                                <br />
                                <strong>
                                    Last Modification:
                                    <br />
                                </strong>
                                {selectedData.last_modification_date}
                            </span>
                        </div>
                        <div className={classes.sideBarColumn}>
                            <span>
                                <strong>Documentation:</strong>
                                <br />
                                {selectedData.doc}
                            </span>
                        </div>
                        <div className={classes.sideBarColumn}>
                            <span>
                                <strong>Reads and writes to:</strong>
                                <br />
                                <TableList
                                    tables={selectedData.read_write_tables}
                                    classes={classes}
                                />
                                <strong>Reads from:</strong>
                                <br />
                                <TableList
                                    tables={selectedData.read_only_tables}
                                    classes={classes}
                                />
                            </span>
                        </div>
                    </>
                ) : data ? (
                    <div className={classes.sideBarColumn}>
                        <h3>Select a node to see details</h3>
                        <strong>Pipeline name:</strong> {data.name}
                        <br />
                        <strong>Description:</strong> {data.description}
                    </div>
                ) : null}
            </div>
        )
    }
}

class TableList extends Component {
    render() {
        const { classes } = this.props
        const tables_split = {}
        for (const table of this.props.tables) {
            const split_table = table.split('.')
            let schema
            let table_name
            if (split_table.length > 1) {
                schema = split_table[0]
                table_name = split_table[1]
            } else {
                schema = 'public'
                table_name = split_table[0]
            }
            if (!(schema in tables_split)) {
                tables_split[schema] = [table_name]
            } else {
                tables_split[schema].push(table_name)
            }
        }

        return Object.entries(tables_split).map((k) => (
            <span key={k[0]} className={classes.codeFont}>
                {k[0]}: <br />
                {k[1].map((table) => (
                    <span key={table} className={classes.codeFont}>
                        &nbsp;&nbsp;&nbsp;&nbsp;{table}
                        <br />
                    </span>
                ))}
            </span>
        ))
    }
}

export default withRouter(withStyles(styles)(PipelineGraph))
