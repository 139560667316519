import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { getCompletedAuctionModelRounds } from '../../../api'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const AuctionModelPipelineRoundSelect = (props) => {
    const { onChange, formContext } = props
    const [auctionModelRounds, setAuctionModelRounds] = useState([])

    const [round, setRound] = useState('')

    const classes = useStyles()

    const handleChange = (event) => {
        let round = event.target.value
        setRound(round)
        onChange(round)
    }

    useEffect(() => {
        const getAuctionModelPipelineRounds = async () => {
            let auctionModelPipelineId =
                formContext.auctionModelPipelineSettings
                    ?.inheritedAuctionModelPipelineId

            if (
                auctionModelPipelineId !== null &&
                auctionModelPipelineId !== undefined
            ) {
                const res = await getCompletedAuctionModelRounds(
                    auctionModelPipelineId
                )

                if (res.error !== null) {
                    // TODO: Figure how to inject errors into RSJF validation
                    console.error('Error retrieving auction model rounds.')
                } else {
                    setAuctionModelRounds(res.data)
                }
            }
        }

        getAuctionModelPipelineRounds().catch(console.error)
    }, [
        formContext.auctionModelPipelineSettings
            ?.inheritedAuctionModelPipelineId,
    ])
    return (
        <div>
            {auctionModelRounds.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                        Auction Model Round
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={round}
                        onChange={handleChange}
                    >
                        {auctionModelRounds.map((round) => (
                            <MenuItem value={round} key={round}>
                                {round.toString()}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <div>No rounds available to choose</div>
            )}
        </div>
    )
}

export default AuctionModelPipelineRoundSelect
