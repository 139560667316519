import { Tooltip, Button, CircularProgress } from '@material-ui/core'
import Replay from '@material-ui/icons/Replay'
import StopIcon from '@material-ui/icons/Stop'

const CancelOrRestart = ({ status, handleAction, disabled = false }) => {
    if (status === 'Canceling') {
        return (
            <Tooltip title={status}>
                <CircularProgress size={24} />
            </Tooltip>
        )
    }
    const isRunning = status === 'Running'
    return (
        <Tooltip title={isRunning ? 'Cancel pipeline' : 'Restart pipeline'}>
            <Button
                disabled={disabled}
                component="label"
                color="primary"
                startIcon={isRunning ? <StopIcon /> : <Replay />}
                onClick={(event) => {
                    handleAction(event, isRunning ? 'cancel' : 'restart')
                }}
            ></Button>
        </Tooltip>
    )
}

export default CancelOrRestart
