import React, { useState, useEffect } from 'react'
import { Grid, Select, MenuItem, Button } from '@material-ui/core'
import {
    getMiniMasterRunVersion,
    addSelectedMiniMasterScenario,
    resetPostProcPipelineTasks,
    cancelOrRestartPipeline,
} from '../../api'

const SelectMiniMasterRunVersion = ({
    pipelineId,
    showAlert,
    refreshPage,
    pipelineName,
    setLoading,
}) => {
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState('')
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const res = await getMiniMasterRunVersion(pipelineId)

                if (res.error !== null) {
                    showAlert(
                        'Failed to retrieve run versions.  Please wait and try again.'
                    )
                } else {
                    setOptions(
                        res.data.map((option) => ({
                            value: option,
                            label: option,
                        }))
                    )
                }
            } catch (error) {
                showAlert(
                    'Failed to retrieve run versions.  Please wait and try again.'
                )
                console.error('Error fetching options:', error)
            }
        }

        fetchOptions()
    }, [showAlert, pipelineId])

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value
        setSelectedOption(selectedValue)
        setIsButtonDisabled(false)
    }

    const handleButtonClick = async () => {
        setLoading(true)
        addSelectedMiniMasterScenario(pipelineId, selectedOption)
        resetPostProcPipelineTasks(pipelineId)
        try {
            const res = await cancelOrRestartPipeline(
                pipelineName,
                'restart',
                pipelineId
            )

            if (res.error !== null) {
                showAlert('Failed to restart pipeline.')
            }
        } catch (error) {
            showAlert('Failed to restart pipeline.')
            console.error('Error fetching options:', error)
        }
        setLoading(false)
        refreshPage()
    }

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    displayEmpty
                    fullWidth
                >
                    <MenuItem value="" disabled>
                        Select a Scenario
                    </MenuItem>
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    onClick={handleButtonClick}
                >
                    Post-Process Scenario
                </Button>
            </Grid>
        </Grid>
    )
}

export default SelectMiniMasterRunVersion
