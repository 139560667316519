import { TextField } from '@material-ui/core'
import { useEffect, useState } from 'react'

const PortfolioStatsAuctionTypeWidget = (props) => {
    const [auctionSequenceState, setAuctionSequenceState] = useState(null)

    const { value, onChange, formContext, id } = props

    const decodeIdInArray = (tentativeId, monthsToRun) => {
        // Get the current id of formContext?.monthsToRun (an array)
        // if the format of the id is wrong or the element does not exist in the array, returns null
        try {
            // pattern of tentativeId: "root_monthsToRun_1_auctionType"
            let idInArray = parseInt(
                tentativeId.split('monthsToRun_')[1].replace('_auctionType', '')
            )
            if (
                monthsToRun[idInArray] !== null &&
                monthsToRun[idInArray] !== undefined
            ) {
                return idInArray
            }
        } catch (error) {}
        return null
    }

    const getNullIfUndefined = (valueIn) => {
        if (valueIn === undefined) {
            return null
        }
        return valueIn
    }

    const getAuctionType = (auctionSequence) => {
        if (auctionSequence !== null) {
            if (auctionSequence > 0) {
                return 'Annual'
            } else if (auctionSequence === 0) {
                return 'Monthly'
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    useEffect(() => {
        try {
            let monthsToRun = formContext?.monthsToRun
            let idInArray = decodeIdInArray(id, monthsToRun)

            if (idInArray !== null) {
                // Detect changes
                let currentAuctionSequence = getNullIfUndefined(
                    monthsToRun[idInArray].auctionSequence
                )
                if (currentAuctionSequence !== auctionSequenceState) {
                    setAuctionSequenceState(currentAuctionSequence)

                    onChange(getAuctionType(currentAuctionSequence))
                }
            }
        } catch (error) {
            onChange('ERROR')
        }
    }, [formContext?.monthsToRun, onChange])

    return (
        <TextField
            variant="outlined"
            label="Auction Type"
            value={value || ''}
            disabled
        />
    )
}
export default PortfolioStatsAuctionTypeWidget
