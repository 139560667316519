import {
    Accordion,
    AccordionSummary,
    Grid,
    Typography,
    CircularProgress,
    AccordionDetails,
    Divider,
    Button,
} from '@material-ui/core'
import { Component } from 'react'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CreateDAMLoopScenario from './CreateDAMLoopScenario'
import {
    createDAMLoopScenario,
    getDAMLoopScenarios,
    updateDAMLoopInterval,
    uploadCustomDAMLoopFile,
    getIntervalGdxFiles,
    getPresignedUrl,
    getIntervalTimeline,
    deleteDAMLoopScenario,
    getDefaultAdvancedDAMLoopSettings,
} from '../../../api'
import DamLoopScenarioTracker from './DAMLoopScenarioTracker'
import TimelineModal from './TimelineModal'
import { SocketContext } from '../../../WebSockets/SocketContext'

const preProcessInputHookExample = null
const preProcessModelHookExample = null
const postProcessModelHookExample = null

class DAMLoopWidget extends Component {
    static contextType = SocketContext

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            pipeline: this.props.pipeline,
            createScenarioData: null,
            damLoopScenarios: [],
            initialized: false,
            delay: 30000,
            intervalGDXFiles: {},
            deletionMessagesByScenarioId: {},
            intervalTimelineData: null,
            defaultAdvancedSettings: '',
            preProcessInputHook: preProcessInputHookExample,
            preProcessInputHookUpdated: false,
            preProcessModelHook: preProcessModelHookExample,
            preProcessModelHookUpdated: false,
            postProcessModelHook: postProcessModelHookExample,
            postProcessModelHookUpdated: false,
        }

        this.handleScenarioCreateDataChange =
            this.handleScenarioCreateDataChange.bind(this)
        this.handleCreateDAMLoopScenarioClick =
            this.handleCreateDAMLoopScenarioClick.bind(this)
        this.onUpdateIntervalSettings = this.onUpdateIntervalSettings.bind(this)
        this.onStartInterval = this.onStartInterval.bind(this)
        this.onStopInterval = this.onStopInterval.bind(this)
        this.handleIntervalStateUpdate =
            this.handleIntervalStateUpdate.bind(this)
        this.retrieveAllAvailableIntervalGdxFiles =
            this.retrieveAllAvailableIntervalGdxFiles.bind(this)
        this.downloadGdxFile = this.downloadGdxFile.bind(this)
        this.retrieveIntervalLogs = this.retrieveIntervalLogs.bind(this)
        this.handleTimelineModalClose = this.handleTimelineModalClose.bind(this)
        this.deleteScenario = this.deleteScenario.bind(this)
        this.onDefaultAdvancedSettingsChanged =
            this.onDefaultAdvancedSettingsChanged.bind(this)
        this.onPreProcessInputHookChanged =
            this.onPreProcessInputHookChanged.bind(this)
        this.onPreProcessModelHookChanged =
            this.onPreProcessModelHookChanged.bind(this)
        this.onPostProcessModelHookChanged =
            this.onPostProcessModelHookChanged.bind(this)
        this.handleWebSocket = this.handleWebSocket.bind(this)
    }

    async componentDidUpdate(_prevProps, prevState) {
        if (!this.state.initialized) {
            this.setState({ initialized: true, loading: true })
            this.setState({ loading: true })
            await this.fetchDefaultAdvancedDAMLoopSettings()
            await this.retrieveDAMLoopScenarios()
            this.setState({ loading: false })

            const runningStateExists = this.state.damLoopScenarios.some(
                (scenario) =>
                    scenario.damLoopIntervals.some(
                        (interval) =>
                            ![
                                'COMPLETE',
                                'PENDING',
                                'CANCELLED',
                                'FAILED',
                            ].includes(interval.state)
                    )
            )
            if (runningStateExists) {
                this.interval = setInterval(
                    this.retrieveDAMLoopScenarios,
                    this.state.delay
                )
            }
            await this.handleWebSocket()
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)

        const socket = this.context
        if (socket) {
            socket.off('dam_loop_scenario_deletion_event')
        }
    }

    async handleWebSocket() {
        const socket = this.context
        if (socket) {
            // Listen for data from the server
            socket.onMessage(
                'dam_loop_scenario_deletion_event',
                (incomingData) => {
                    const relevantScenarioDeleteMessage =
                        this.state.damLoopScenarios.some(
                            (scenario) =>
                                scenario.id === incomingData.scenario_id
                        )
                    if (relevantScenarioDeleteMessage) {
                        this.setState((prevState) => ({
                            deletionMessagesByScenarioId: {
                                ...prevState.deletionMessagesByScenarioId,
                                [incomingData.scenario_id]:
                                    incomingData.message,
                            },
                        }))
                    }
                }
            )
        }
    }

    downloadGdxFile = async (key, filename) => {
        const res = await getPresignedUrl(key, 'get_object')
        if (res.error !== null) {
            if (res.data === null)
                this.props.showAlert('Failed to download file')
            else this.props.showAlert(res.data)
        } else {
            const presignedUrl = res.data
            const link = document.createElement('a')
            link.href = presignedUrl
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }

    fetchDefaultAdvancedDAMLoopSettings = async () => {
        try {
            const res = await getDefaultAdvancedDAMLoopSettings()
            if (res.error !== null) {
                this.props.showAlert(
                    'Failed to retrieve Default Advanced DAM Loop.  Please wait and try again.'
                )
            } else {
                this.setState({ defaultAdvancedSettings: res.data })
            }
        } catch (error) {
            this.props.showAlert(
                'Failed to retrieve Default Advanced DAM Loop.  Please wait and try again.'
            )
            console.error('Error fetching options:', error)
        }
    }

    retrieveIntervalLogs = async (intervalId) => {
        const res = await getIntervalTimeline(intervalId)

        if (res.error !== null) {
            if (res.data === null)
                this.props.showAlert(
                    `Failed to get timeline for interval ${intervalId}`
                )
            else this.props.showAlert(res.data)
        } else {
            this.setState({ intervalTimelineData: res.data })
        }
    }

    retrieveAllAvailableIntervalGdxFiles = async (intervalIds) => {
        let allIntervalGdxFiles = {}

        for (let i = 0; i < intervalIds.length; i++) {
            const res = await getIntervalGdxFiles(intervalIds[i])

            if (res.error !== null) {
                if (res.data === null)
                    this.props.showAlert(
                        `Failed to check GDX Files for interval ${intervalIds[i]}`
                    )
                else this.props.showAlert(res.data)
            } else {
                if (res.data.length > 0) {
                    allIntervalGdxFiles[intervalIds[i]] = res.data
                }
            }
        }

        this.setState({ intervalGDXFiles: allIntervalGdxFiles })
    }

    retrieveDAMLoopScenarios = async () => {
        const res = await getDAMLoopScenarios(this.state.pipeline.id)
        if (res.error !== null) {
            if (res.data === null)
                this.props.showAlert('Failed to retrieve new DAM Loop Scenario')
            else this.props.showAlert(res.data)
        } else {
            const retrievedDAMLoopScenarios = res.data
            this.setState({ damLoopScenarios: retrievedDAMLoopScenarios })
            const intervalIds = []

            retrievedDAMLoopScenarios.forEach((scenario) => {
                scenario.damLoopIntervals.forEach((interval) => {
                    intervalIds.push(interval.id)
                })
            })
            await this.retrieveAllAvailableIntervalGdxFiles(intervalIds)

            const runningStateExists = this.state.damLoopScenarios.some(
                (scenario) =>
                    scenario.damLoopIntervals.some(
                        (interval) =>
                            ![
                                'COMPLETE',
                                'PENDING',
                                'CANCELLED',
                                'FAILED',
                            ].includes(interval.state)
                    )
            )

            if (!runningStateExists) {
                clearInterval(this.interval)
                this.interval = null
            }
        }
    }

    handleTimelineModalClose = () => {
        this.setState({ intervalTimelineData: null })
    }

    handleScenarioCreateDataChange = (createScenarioData) => {
        this.setState({ createScenarioData: createScenarioData })
    }

    onDefaultAdvancedSettingsChanged = (value) => {
        this.setState({ defaultAdvancedSettings: value })
    }

    onPreProcessInputHookChanged = (value) => {
        this.setState({
            preProcessInputHook: value,
            preProcessInputHookUpdated: true,
        })
    }

    onPreProcessModelHookChanged = (value) => {
        this.setState({
            preProcessModelHook: value,
            preProcessModelHookUpdated: true,
        })
    }

    onPostProcessModelHookChanged = (value) => {
        this.setState({
            postProcessModelHook: value,
            postProcessModelHookUpdated: true,
        })
    }

    handleCreateDAMLoopScenarioClick = async () => {
        this.setState({ loading: true })

        let customArtifactSuccessfullyUploaded = true
        let dataPayload = this.state.createScenarioData

        dataPayload['advancedSettings'] = this.state.defaultAdvancedSettings

        dataPayload['preProcessInputHook'] = this.state
            .preProcessInputHookUpdated
            ? this.state.preProcessInputHook
            : null

        dataPayload['preProcessModelHook'] = this.state
            .preProcessModelHookUpdated
            ? this.state.preProcessModelHook
            : null

        dataPayload['postProcessModelHook'] = this.state
            .postProcessModelHookUpdated
            ? this.state.postProcessModelHook
            : null

        if (
            this.state.createScenarioData.customDAMLoopArtifacts !== null &&
            this.state.createScenarioData.customDAMLoopArtifacts !== undefined
        ) {
            let res = await uploadCustomDAMLoopFile(
                this.state.createScenarioData.customDAMLoopArtifacts,
                this.state.pipeline.id
            )
            if (res.error !== null) {
                if (res.data === null)
                    this.props.showAlert('Failed to upload custom artifacts')
                else this.props.showAlert(res.data)
                customArtifactSuccessfullyUploaded = false
            } else {
                let customArtifactsPipelineFileId = res.data['id']
                dataPayload['customArtifactsPipelineFileId'] =
                    customArtifactsPipelineFileId
            }
        }

        delete dataPayload['customDAMLoopArtifacts']

        if (customArtifactSuccessfullyUploaded) {
            let res = await createDAMLoopScenario(dataPayload)

            if (res.error !== null) {
                if (res.data === null)
                    this.props.showAlert(
                        'Failed to start new DAM Loop Scenario'
                    )
                else this.props.showAlert(res.data)
            } else {
                const newScenario = res.data
                this.setState((prevState) => ({
                    damLoopScenarios: [
                        ...prevState.damLoopScenarios,
                        newScenario,
                    ],
                }))
            }
        }
        this.setState({
            loading: false,
            createDAMLoopScenario: {
                name: 'New Scenario',
                damLoopIntervals: [],
                customDAMLoopArtifacts: null,
                pipelineId: this.state.pipeline.id,
            },
        })
    }

    handleIntervalStateUpdate = async (interval) => {
        this.setState({ loading: true })
        const res = await updateDAMLoopInterval(interval)
        if (res.error !== null) {
            if (res.data === null)
                this.props.showAlert('Failed to update new DAM Loop Interval')
            else this.props.showAlert(res.data)
        } else {
            const updatedInterval = res.data

            this.onUpdateIntervalSettings(
                updatedInterval.dam_loop_scenario_id,
                updatedInterval.id,
                updatedInterval
            )

            if (
                !['COMPLETE', 'PENDING', 'CANCELLED', 'FAILED'].includes(
                    updatedInterval.state
                ) &&
                !this.interval
            ) {
                this.interval = setInterval(
                    this.retrieveDAMLoopScenarios,
                    this.state.delay
                )
            }
        }
        this.setState({ loading: false })
    }

    onStopInterval = async (interval) => {
        interval['start'] = false
        await this.handleIntervalStateUpdate(interval)
    }

    onStartInterval = async (interval) => {
        interval['start'] = true
        await this.handleIntervalStateUpdate(interval)
    }

    deleteScenario = async (scenarioId) => {
        this.setState({ loading: true })

        const res = await deleteDAMLoopScenario(scenarioId)
        if (res.error !== null) {
            this.props.showAlert(
                'Failed to delete DAM Loop Scenario please try again later'
            )
        } else {
            this.setState((prevState) => ({
                damLoopScenarios: prevState.damLoopScenarios.map((scenario) =>
                    scenario.id === scenarioId ? res.data : scenario
                ),
            }))
        }

        this.setState({ loading: false })
    }

    onUpdateIntervalSettings = (scenarioId, intervalId, intervalData) => {
        // Find the scenario in damLoopScenarios and update its intervals
        this.setState((prevState) => {
            const updatedScenarios = prevState.damLoopScenarios.map(
                (scenario) => {
                    if (scenario.id === scenarioId) {
                        // Find the interval within the scenario and update it
                        const updatedIntervals = scenario.damLoopIntervals.map(
                            (interval) => {
                                if (interval.id === intervalId) {
                                    return {
                                        ...interval,
                                        ...intervalData,
                                    }
                                }
                                return interval
                            }
                        )
                        return {
                            ...scenario,
                            damLoopIntervals: updatedIntervals,
                        }
                    }
                    return scenario
                }
            )
            return {
                damLoopScenarios: updatedScenarios,
            }
        })
    }

    render() {
        const classes = this.props.classes

        return (
            <Grid item xs={12}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Typography className={classes.heading}>
                                DAM Loop Scenarios
                            </Typography>
                            <div>
                                {this.state.loading && (
                                    <CircularProgress
                                        color="primary"
                                        size={25}
                                    />
                                )}
                            </div>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            className={classes.accordionContentGrid}
                        >
                            {this.state.damLoopScenarios.map((scenario) => {
                                const deletionMessage =
                                    this.state.deletionMessagesByScenarioId[
                                        scenario.id
                                    ]
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.accordionContentGrid}
                                        key={scenario.id}
                                    >
                                        <DamLoopScenarioTracker
                                            damLoopScenario={scenario}
                                            onUpdateIntervalSettings={
                                                this.onUpdateIntervalSettings
                                            }
                                            onDeleteScenario={
                                                this.deleteScenario
                                            }
                                            onStopInterval={this.onStopInterval}
                                            onStartInterval={
                                                this.onStartInterval
                                            }
                                            intervalGDXFiles={
                                                this.state.intervalGDXFiles
                                            }
                                            downloadGdxFile={
                                                this.downloadGdxFile
                                            }
                                            retrieveIntervalLogs={
                                                this.retrieveIntervalLogs
                                            }
                                            deletionMessage={deletionMessage}
                                            loading={this.state.loading}
                                        />
                                        <Divider style={{ width: '100%' }} />
                                    </Grid>
                                )
                            })}
                            <Grid
                                item
                                xs={12}
                                className={classes.accordionContentGrid}
                            >
                                <CreateDAMLoopScenario
                                    intervalCount={
                                        this.state.pipeline.run_settings
                                            .intervals || 0
                                    }
                                    onDataUpdated={
                                        this.handleScenarioCreateDataChange
                                    }
                                    monthlyAuction={
                                        this.state.pipeline.run_settings
                                            .auction_type === 'Monthly'
                                    }
                                    pipelineId={this.state.pipeline.id}
                                    defaultAdvancedSettings={
                                        this.state.defaultAdvancedSettings
                                    }
                                    preProcessInputHook={
                                        this.state.preProcessInputHook
                                    }
                                    preProcessModelHook={
                                        this.state.preProcessModelHook
                                    }
                                    postProcessModelHook={
                                        this.state.postProcessModelHook
                                    }
                                    onDefaultAdvancedSettingsChanged={
                                        this.onDefaultAdvancedSettingsChanged
                                    }
                                    onPreProcessInputHookChanged={
                                        this.onPreProcessInputHookChanged
                                    }
                                    onPreProcessModelHookChanged={
                                        this.onPreProcessModelHookChanged
                                    }
                                    onPostProcessModelHookChanged={
                                        this.onPostProcessModelHookChanged
                                    }
                                />
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        disabled={
                                            this.state.loading ||
                                            !this.state.createScenarioData ||
                                            !this.state.createScenarioData
                                                .damPipelineScenarioId
                                        }
                                        onClick={
                                            this
                                                .handleCreateDAMLoopScenarioClick
                                        }
                                    >
                                        Create DAM Scenario
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <TimelineModal
                            intervalTimelineData={
                                this.state.intervalTimelineData
                            }
                            onCloseModal={this.handleTimelineModalClose}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }
}

export default DAMLoopWidget
