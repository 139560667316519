import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { getDamPipelineScenariosFromDataExtractionPipelineId } from '../../api'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
    selectContainer: {
        marginRight: theme.spacing(5), // Using theme.spacing for better consistency and responsiveness
    },
}))

const DAMPipelineScenarioSelect = (props) => {
    const { onChange, pipelineId } = props
    const [damPipelineScenarios, setDamPipelineScenarios] = useState([])

    const [scenario, setScenario] = useState('')

    const classes = useStyles()

    const handleChange = (event) => {
        let scenario = event.target.value
        setScenario(scenario)
        onChange(scenario)
    }

    useEffect(() => {
        const getDamPipelineScenarios = async () => {
            if (pipelineId !== null && pipelineId !== undefined) {
                const res =
                    await getDamPipelineScenariosFromDataExtractionPipelineId(
                        pipelineId
                    )
                if (res.error !== null) {
                    console.error('Error retrieving DAM Pipeline Scenarios.')
                } else {
                    setDamPipelineScenarios(res.data)
                }
            }
        }

        getDamPipelineScenarios().catch(console.error)
    }, [pipelineId])
    return (
        <div className={classes.selectContainer}>
            {damPipelineScenarios.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <InputLabel>DAM Pipeline Scenario ID</InputLabel>
                    <Select value={scenario} onChange={handleChange}>
                        {damPipelineScenarios.map((damScenario) => (
                            <MenuItem
                                value={damScenario.scenarioId}
                                key={damScenario.scenarioId}
                            >
                                {`${damScenario.scenarioName} - ${damScenario.scenarioId}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <div>No DAM Pipeline Scenarios available to choose</div>
            )}
        </div>
    )
}

export default DAMPipelineScenarioSelect
