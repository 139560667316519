import { Component } from 'react'
import { withRouter } from 'react-router'
import { Button, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Layout from '../Layout'

import Drawer from '@material-ui/core/Drawer'

import ReactFlow, { Controls } from 'react-flow-renderer'

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            drawerOpen: false,
            nodeContent: {},
        }
    }

    render() {
        const elements = [
            {
                id: 'horizontal-1',
                type: 'input',
                sourcePosition: 'bottom',
                data: {
                    label: 'External Sources',
                    title: 'External Sources',
                    subtitle: 'Raw Data',
                    description:
                        'Data from different sources is collected on an automatic system. It is marked by date and filename and stored in our database. The data is uploaded exactly as it is received with no alterations or manipulations in order to maintain the initial raw information. Our sources include:',
                    list: ['ERCOT', 'NOAA', 'EIA', 'NGI', 'IRR'],
                    link: [
                        {
                            comingSoon: true,
                            caption: 'To see the latest uploads ',
                            url: '/',
                        },
                    ],
                },
                position: { x: 20, y: 120 },
                style: { borderColor: 'blue' },
            },
            {
                id: 'horizontal-2',
                sourcePosition: 'top',
                data: {
                    label: 'User Files I',
                    subtitle: 'Design parameters & user data',
                    description:
                        'The data in these tables can be divided in two categories:',
                    list: [
                        'Model design: The information is necessary for the model functionality. The user can update these values whenever there are structural changes to the model.',
                        'User Inputs: The data includes generic values, fuel compositions and manual analysis necessary to match information between sources.',
                    ],
                    link: [
                        {
                            comingSoon: true,
                            caption: 'To see the latest uploads ',
                            url: '/',
                        },
                        {
                            comingSoon: true,
                            caption: 'To upload a New File ',
                            url: '/',
                        },
                    ],
                },
                position: { x: 100, y: 320 },
                style: { borderColor: 'red', color: 'red' },
            },
            {
                id: 'horizontal-3',
                style: { borderColor: 'blue' },
                data: {
                    label: 'Daily Data Processing',
                    subtitle: 'Routine calculations',
                    description:
                        'These are routine processes that have to happen daily in order to prepare the data for pipeline runs. These include the calculation of prices for fuel combinations, classification of hydro offers and network model rebuilds.',
                    link: [
                        {
                            comingSoon: true,
                            caption: 'To see the latest results ',
                            url: '/',
                        },
                    ],
                },
                position: { x: 250, y: 200 },
            },
            {
                id: 'horizontal-4',
                type: 'input',
                sourcePosition: 'right',
                data: {
                    label: 'User Files II',
                    subtitle: 'Added elements & run specific data',
                    description:
                        'The data in these tables can be divided in two categories:',
                    list: [
                        'Added Elements: This system allows the user to add an element of any type to the existing network in order to see how it would respond.For New generation resources, this is divided into completely new resources or those already identified by EIA.These files are optional.',
                        'Run Specific Data: The user has to upload a new version of the load adjustment factors per run.',
                    ],
                    link: [
                        {
                            comingSoon: false,
                            caption: 'To see the latest uploads ',
                            url: '/run_configurations',
                        },
                        {
                            comingSoon: false,
                            caption: 'To upload a new set of files ',
                            url: '/run_configuration/create',
                        },
                    ],
                },
                style: { borderColor: 'red', color: 'red' },
                position: { x: 350, y: 320 },
            },
            {
                id: 'horizontal-5',
                type: 'input',
                sourcePosition: 'bottom',
                data: {
                    label: 'Run Parameters',
                    subtitle: 'Pipeline starting point',
                    description:
                        'The user need to input a few parameters that define the data processing. In order to start a new pipeline, the user has to define the dates for the run, cutoff dates for different data sources and the year the system is going to use as a weather sample.',
                    link: [
                        {
                            comingSoon: false,
                            caption: 'To start a new pipeline ',
                            url: '/data_transformation_pipeline/create',
                        },
                        {
                            comingSoon: false,
                            caption:
                                'To use the same parameters as a previous run ',
                            url: '/',
                        },
                    ],
                },
                style: { borderColor: 'green', color: 'green' },
                position: { x: 500, y: 70 },
            },
            {
                id: 'horizontal-6',
                data: { label: 'Data Processing' },
                style: { borderColor: 'blue' },
                position: { x: 500, y: 200 },
            },
            {
                id: 'horizontal-7',
                type: 'input',
                data: { label: 'User Files III' },
                sourcePosition: 'top',
                style: { borderColor: 'red', color: 'red' },
                position: { x: 650, y: 320 },
            },
            {
                id: 'horizontal-8',
                type: 'input',
                data: { label: 'Nodal Model Configuration' },
                style: { borderColor: 'green', color: 'green' },
                position: { x: 700, y: 70 },
            },
            {
                id: 'horizontal-9',
                data: { label: 'Data Assembly' },
                style: { borderColor: 'blue' },
                position: { x: 700, y: 200 },
            },
            {
                id: 'horizontal-10',
                targetPosition: 'left',
                sourcePosition: 'right',
                data: { label: 'Optimization Model' },
                style: { borderColor: 'blue' },
                position: { x: 900, y: 200 },
            },
            {
                id: 'horizontal-11',
                type: 'input',
                data: { label: 'Data Analysis Parameters' },
                style: { borderColor: 'green', color: 'green' },
                position: { x: 1100, y: 70 },
            },

            {
                id: 'horizontal-12',
                data: { label: 'Results Processing' },
                targetPosition: 'top',
                sourcePosition: 'right',
                style: { borderColor: 'blue' },
                position: { x: 1100, y: 200 },
            },
            {
                id: 'horizontal-13',
                type: 'input',
                data: { label: 'CRR Model Configuration' },
                style: { borderColor: 'green', color: 'green' },
                position: { x: 1300, y: 70 },
            },

            {
                id: 'horizontal-14',
                data: { label: 'CRR Optimization Model' },
                sourcePosition: 'right',
                style: { borderColor: 'blue' },
                position: { x: 1300, y: 200 },
            },

            {
                id: 'horizontal-15',
                type: 'output',
                targetPosition: 'left',
                data: { label: 'CRR Selection/Auction' },
                style: { borderColor: 'blue' },
                position: { x: 1500, y: 200 },
            },

            {
                id: 'horizontal-e1-2',
                source: 'horizontal-1',
                type: 'smoothstep',
                target: 'horizontal-3',
                animated: true,
            },
            {
                id: 'horizontal-e2-3',
                source: 'horizontal-2',
                type: 'smoothstep',
                target: 'horizontal-3',
                animated: true,
            },
            {
                id: 'horizontal-e3-6',
                source: 'horizontal-3',
                type: 'smoothstep',
                target: 'horizontal-6',
                animated: true,
            },
            {
                id: 'horizontal-e4-6',
                source: 'horizontal-4',
                type: 'smoothstep',
                target: 'horizontal-6',
                animated: true,
            },
            {
                id: 'horizontal-e5-6',
                source: 'horizontal-5',
                target: 'horizontal-6',
                type: 'step',
                style: { stroke: 'green' },
                label: 'Manual Entry',
                labelStyle: { fill: 'green', fontWeight: 700 },
            },
            {
                id: 'horizontal-e6-9',
                source: 'horizontal-6',
                type: 'smoothstep',
                target: 'horizontal-9',
                animated: true,
            },
            {
                id: 'horizontal-e7-9',
                source: 'horizontal-7',
                type: 'smoothstep',
                target: 'horizontal-9',
                animated: true,
            },
            {
                id: 'horizontal-e9-10',
                source: 'horizontal-9',
                type: 'step',
                target: 'horizontal-10',
                animated: true,
            },
            {
                id: 'horizontal-e8-9',
                source: 'horizontal-8',
                target: 'horizontal-9',
                type: 'step',
                style: { stroke: 'green' },
                label: 'Manual Entry',
                labelStyle: { fill: 'green', fontWeight: 700 },
            },
            {
                id: 'horizontal-e11-12',
                source: 'horizontal-11',
                target: 'horizontal-12',
                type: 'step',
                style: { stroke: 'green' },
                label: 'Manual Entry',
                labelStyle: { fill: 'green', fontWeight: 700 },
            },
            {
                id: 'horizontal-e10-12',
                source: 'horizontal-10',
                type: 'smoothstep',
                target: 'horizontal-12',
                animated: true,
            },
            {
                id: 'horizontal-e12-14',
                source: 'horizontal-12',
                type: 'smoothstep',
                target: 'horizontal-14',
                animated: true,
            },
            {
                id: 'horizontal-e13-14',
                source: 'horizontal-13',
                target: 'horizontal-14',
                type: 'step',
                style: { stroke: 'green' },
                label: 'Manual Entry',
                labelStyle: { fill: 'green', fontWeight: 700 },
            },
            {
                id: 'horizontal-e14-15',
                source: 'horizontal-14',
                type: 'smoothstep',
                target: 'horizontal-15',
                animated: true,
            },
        ]

        const drawerContent = () => {
            const { label, description, list, subtitle, link } =
                this.state.nodeContent
            return (
                <div
                    style={{ padding: '3rem 5rem' }}
                    role="presentation"
                    onClick={() => this.setState({ drawerOpen: false })}
                    onKeyDown={() => this.setState({ drawerOpen: false })}
                >
                    <Typography variant="h4" color="primary">
                        {label}
                    </Typography>
                    <Typography variant="h6" gutterBottom color="textSecondary">
                        {subtitle}
                    </Typography>
                    <Typography variant="body1">{description}</Typography>
                    {list && (
                        <ul>
                            {list.map((item, index) => (
                                <li key={index}>
                                    <Typography variant="body1">
                                        {item}
                                    </Typography>{' '}
                                </li>
                            ))}
                        </ul>
                    )}
                    {link && (
                        <ul style={{ padding: 0 }}>
                            {link.map((item, index) => (
                                <li style={{ listStyle: 'none' }} key={index}>
                                    <Typography variant="subtitle1">
                                        {item.caption}{' '}
                                        {item.comingSoon ? (
                                            <Link
                                                to={item.url}
                                                style={{
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    disabled
                                                >
                                                    click here (Coming Soon)
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Link
                                                to={item.url}
                                                style={{
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                >
                                                    click here
                                                </Button>
                                            </Link>
                                        )}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )
        }

        const testMethod = (e, element) => {
            this.setState({
                drawerOpen: true,
                nodeContent: element.data,
            })
        }

        return (
            <Layout>
                <div style={{ height: '100%' }}>
                    <ReactFlow
                        elements={elements}
                        nodesDraggable={false}
                        nodesConnectable={false}
                        onElementClick={(e, element) => testMethod(e, element)}
                    >
                        <Controls />
                    </ReactFlow>
                </div>
                <Drawer
                    anchor="bottom"
                    open={this.state.drawerOpen}
                    onClose={() => this.setState({ drawerOpen: false })}
                >
                    {drawerContent()}
                </Drawer>
            </Layout>
        )
    }
}

export default withRouter(Home)
