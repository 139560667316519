import React, { Component, createContext } from 'react'
import { WebSocketWithInterceptors } from './SocketInterceptor'

export const SocketContext = createContext()

export class SocketProvider extends Component {
    state = {
        socket: null,
    }

    componentDidMount() {
        let url
        if (process.env.NODE_ENV === 'production') {
            url = null
        } else {
            url = 'http://localhost:5000'
        }
        const options = {
            transports: ['websocket'],
        }

        let newSocket
        try {
            newSocket = new WebSocketWithInterceptors(url, options)
            this.setState({ socket: newSocket })
        } catch (error) {
            console.error('Failed to create WebSocket connection:', error)
        }
    }

    componentWillUnmount() {
        if (this.state.socket) {
            this.state.socket.disconnect()
        }
    }

    render() {
        return (
            <SocketContext.Provider value={this.state.socket}>
                {this.props.children}
            </SocketContext.Provider>
        )
    }
}
