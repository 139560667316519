import { Component } from 'react'
import {
    Button,
    withStyles,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    CircularProgress,
    TableBody,
    Snackbar,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { withRouter } from 'react-router'
import Layout from '../Layout'
import AddIcon from '@material-ui/icons/Add'
import axios from 'axios'
import { convertToLocalTime } from '../Utils/TimeUtil'
import { getAllEmptyPipelineSettingsFiles } from '../api'

const styles = (theme) => ({
    addButton: {
        float: 'right',
        margin: '.75rem',
    },
    sectionHeader: {
        padding: '1.2rem 0',
        display: 'flex',
        flexWrap: 'nowrap',
    },
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    clickableRow: {
        cursor: 'pointer',
        userSelect: 'none',
    },
})

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

class PipelineSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pipelineSettings: [],
            loading: true,
            totalCount: 0,
            page: 0,
            rowsPerPage: 10,
            alertOpen: false,
            alertMessage: '',
        }
    }

    handleChangePage = async (event, newPage) => {
        this.setState(
            {
                page: newPage,
            },
            this.updatePipelineSettings
        )
    }

    handleChangeRowsPerPage = async (event) => {
        this.setState(
            {
                rowsPerPage: event.target.value,
            },
            this.updatePipelineSettings
        )
    }

    handleDownloadBlankFiles = async (event) => {
        this.setState({ loading: true })
        const { settings_type } = this.props
        const res = await getAllEmptyPipelineSettingsFiles(settings_type)

        if (res.error !== null) {
            if (res.data === null)
                this.showAlert(
                    `Failed to download blank pipeline settings files`
                )
            else this.showAlert(res.data)
        }
        this.setState({ loading: false })
    }

    showAlert(message) {
        this.setState({
            alertOpen: true,
            alertMessage: message,
        })
    }

    async componentDidMount() {
        await this.updatePipelineSettings()
    }

    async componentDidUpdate(prevProps) {
        if (this.props.settings_type !== prevProps.settings_type)
            await this.updatePipelineSettings()
    }

    async updatePipelineSettings() {
        const { settings_type } = this.props
        const results = await axios.get(
            `/pipeline_settings/list?page=${this.state.page}&limit=${this.state.rowsPerPage}&settings_type=${settings_type}`
        )

        this.setState({
            loading: false,
            totalCount: results.data.count,
            pipelineSettings: results.data.results,
        })
    }

    render() {
        const { classes, history, settings_type } = this.props

        return (
            <Layout>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={() =>
                        history.push({
                            pathname: `/${settings_type}/create`,
                        })
                    }
                >
                    Create New
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={this.handleDownloadBlankFiles}
                >
                    Download Blank Files
                </Button>
                <div style={{ marginBottom: '2rem' }}>
                    {this.state.pipelineSettings &&
                        this.state.pipelineSettings.length > 0 &&
                        !this.state.loading && (
                            <>
                                <Typography
                                    component="div"
                                    className={classes.sectionHeader}
                                >
                                    <Typography variant="h5" gutterBottom>
                                        {settings_type === 'run_configuration'
                                            ? 'Existing Run Configurations'
                                            : settings_type ===
                                                'run_modification'
                                              ? 'Existing Run Modifications'
                                              : 'Existing Custom Auction Modifications'}
                                    </Typography>
                                </Typography>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Id</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Creator</TableCell>
                                            <TableCell>Created Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.pipelineSettings.map(
                                            (row) => (
                                                <TableRow
                                                    className={
                                                        classes.clickableRow
                                                    }
                                                    key={row.id}
                                                    hover
                                                    onClick={() =>
                                                        history.push(
                                                            `/${settings_type}/${row.id}`
                                                        )
                                                    }
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.created_by}
                                                    </TableCell>
                                                    <TableCell>
                                                        {convertToLocalTime(
                                                            row.created_date
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </>
                        )}

                    {this.state.loading && (
                        <div className={classes.loadingIndicator}>
                            <CircularProgress color="primary" size={75} />
                        </div>
                    )}

                    {this.state.pipelineSettings &&
                        this.state.pipelineSettings.length <= 0 &&
                        !this.state.loading && (
                            <Typography
                                component="div"
                                className={classes.sectionHeader}
                            >
                                <Typography variant="h5" gutterBottom>
                                    {settings_type === 'run_configuration'
                                        ? 'No Run Configurations... Create a new one!'
                                        : settings_type === 'run_modification'
                                          ? 'No Run Modifications... Create a new one!'
                                          : 'No Custom Auction Modifications... Create a new one!'}
                                </Typography>
                            </Typography>
                        )}

                    {this.state.pipelineSettings &&
                        this.state.pipelineSettings.length > 0 &&
                        !this.state.loading && (
                            <TablePagination
                                rowsPerPageOptions={[10, 15, 25]}
                                component="div"
                                count={this.state.totalCount || 0}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={
                                    this.handleChangeRowsPerPage
                                }
                            />
                        )}
                </div>
                <Snackbar
                    open={this.state.alertOpen}
                    autoHideDuration={6000}
                    onClose={(e, r) => this.setState({ alertOpen: false })}
                >
                    <Alert
                        onClose={(e, r) => this.setState({ alertOpen: false })}
                        severity="error"
                    >
                        {this.state.alertMessage}
                    </Alert>
                </Snackbar>
            </Layout>
        )
    }
}

export default withRouter(withStyles(styles)(PipelineSettings))
