import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const Auth0ProviderWithHistory = ({ children }) => {
    const history = useHistory()

    const domain = 'bravos-power.us.auth0.com'
    const clientId = 'xIL9fi1uuCoJVIYjKTUFMeHssWSL9tSD'
    const redirectUri = `${window.location.origin}/callback`
    const audience = 'https://bravos-ercot-api.bravos.cloud'

    const onRedirectCallback = (appState) => {
        history.push(appState?.returnTo || window.location.pathname)
    }

    if (!(domain && clientId && redirectUri && audience)) {
        return null
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: redirectUri,
                scope: 'openid profile email offline_access',
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0Provider>
    )
}
