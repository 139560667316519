import { useEffect, useState } from 'react'
import { getMMDPScenarios } from '../../../api'
import {
    List,
    ListItem,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
} from '@material-ui/core'

const MMDPScenarioWidget = (props) => {
    const { value, onChange, formContext } = props
    const [scenarios, setScenarios] = useState([])

    useEffect(() => {
        const asyncFetchGetMMDPScenarios = async () => {
            let masterModelDataPrepPipelineId = null
            if (
                formContext?.miniMasterPipelineSettings !== null &&
                formContext?.miniMasterPipelineSettings !== undefined
            ) {
                masterModelDataPrepPipelineId =
                    formContext?.miniMasterPipelineSettings.mmdpPipelineId
            }
            //
            if (
                masterModelDataPrepPipelineId !== null &&
                masterModelDataPrepPipelineId !== undefined
            ) {
                const res = await getMMDPScenarios(
                    masterModelDataPrepPipelineId
                )
                setScenarios(res.data['scenarios'])
            } else {
                setScenarios([])
            }
        }

        asyncFetchGetMMDPScenarios()
    }, [formContext?.miniMasterPipelineSettings])

    const handleCheckboxChange = (event) => {
        const optionValue = event.target.value
        const isChecked = event.target.checked

        let newSelectedOptions

        if (isChecked) {
            newSelectedOptions = [...value, optionValue]
        } else {
            newSelectedOptions = value.filter((val) => val !== optionValue)
        }

        onChange(newSelectedOptions)
    }

    return (
        <Grid>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Available scenarios:
            </Typography>
            <List>
                {scenarios.map((option) => (
                    <ListItem key={option}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value.includes(option)}
                                    onChange={handleCheckboxChange}
                                    value={option.toString()}
                                />
                            }
                            label={option.toString()}
                        />
                    </ListItem>
                ))}
            </List>
        </Grid>
    )
}
export default MMDPScenarioWidget
