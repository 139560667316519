import { Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import Autocomplete from '../Autocomplete'
import { useState } from 'react'
import axios from 'axios'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    gridItem: {
        width: '100%',
        paddingRight: '.5rem',
    },
    searchItem: {
        width: '100%',
    },
    textField: {
        display: 'flex',
    },
}))

const AuctionIIPipelineSearch = (props) => {
    const classes = useStyles()

    const { onChange } = props
    const [auctionIIPipelineSelection, setAuctionIIPipelineSelection] =
        useState(null)
    const [auctionIIPipelineSearchResults, setAuctionIIPipelineSearchResults] =
        useState([])

    const handleChange = (event, selection) => {
        setAuctionIIPipelineSelection(selection.suggestion)
        onChange(selection.suggestion.id)
    }

    const getSuggestions = async (term) => {
        setAuctionIIPipelineSelection(null)
        if (term.length >= 2) {
            let res = await axios.get(
                `/pipeline/search?pipeline_name=auction_ii_pipeline&term=${term}`
            )
            if (res.status !== 200) {
                setAuctionIIPipelineSearchResults([])
            }
            setAuctionIIPipelineSearchResults(res.data)
        }
    }

    const renderSuggestion = (suggestion, { query, isHighlighted }) => {
        return (
            <MenuItem
                selected={isHighlighted}
                component="div"
                className={classes.searchItem}
            >
                <Typography variant="body2">
                    {suggestion.id} - {suggestion.name}
                </Typography>
            </MenuItem>
        )
    }

    return (
        <Grid container alignItems="flex-start" direction="row">
            <Grid item xs={3} className={classes.gridItem}>
                <Autocomplete
                    placeholder="Auction II Pipeline"
                    getSuggestions={getSuggestions}
                    suggestions={auctionIIPipelineSearchResults}
                    renderSuggestion={renderSuggestion}
                    onSuggestionSelected={(event, suggestion) =>
                        handleChange(event, suggestion)
                    }
                    inputValue={get(
                        auctionIIPipelineSelection,
                        'id',
                        ''
                    ).toString()}
                />
            </Grid>
            <Grid item xs={3} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Name"
                    disabled={true}
                    value={get(auctionIIPipelineSelection, 'name', '')}
                />
            </Grid>
            <Grid item xs={3} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Auction Month"
                    disabled={true}
                    value={get(auctionIIPipelineSelection, 'month', '')}
                />
            </Grid>
            <Grid item xs={3} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Auction Year"
                    disabled={true}
                    value={get(auctionIIPipelineSelection, 'year', '')}
                />
            </Grid>
            <Grid item xs={3} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Auction II ABC Cutoff"
                    disabled={true}
                    value={get(
                        auctionIIPipelineSelection,
                        'abc_cutoff_date',
                        ''
                    )}
                />
            </Grid>
            <Grid item xs={3} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Off Peak TOU"
                    disabled={true}
                    value={get(auctionIIPipelineSelection, 'tou_off_peak', '')}
                />
            </Grid>
            <Grid item xs={3} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Peak Weekday TOU"
                    disabled={true}
                    value={get(auctionIIPipelineSelection, 'tou_peak_wd', '')}
                />
            </Grid>
            <Grid item xs={3} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Peak Weekend TOU"
                    disabled={true}
                    value={get(auctionIIPipelineSelection, 'tou_peak_we', '')}
                />
            </Grid>
        </Grid>
    )
}
export default AuctionIIPipelineSearch
