import React, { useEffect, useState } from 'react'
import {
    getMiniMasterRawDamAnalysisByScenario,
    getMiniMasterRunVersion,
} from '../../../api'
import {
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import DataTable from './DataTable'

const MiMRawDamAnalysis = (props) => {
    const { pipelineId, showAlert } = props
    const [visualizations, setVisualizations] = useState([])
    const [scenarioOptions, setScenarioOptions] = useState([])
    const [selectedScenarioOption, setSelectedScenarioOption] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const res = await getMiniMasterRunVersion(pipelineId)

                if (res.error !== null) {
                    showAlert(
                        'Failed to retrieve run versions.  Please wait and try again.'
                    )
                } else {
                    setScenarioOptions(
                        res.data.map((option) => ({
                            value: option,
                            label: option,
                        }))
                    )
                }
            } catch (error) {
                showAlert(
                    'Failed to retrieve run versions.  Please wait and try again.'
                )
                console.error('Error fetching options:', error)
            }
        }

        fetchOptions()
    }, [showAlert, pipelineId])

    const handleScenarioChange = async (event) => {
        const selectedValue = event.target.value
        setSelectedScenarioOption(selectedValue)
        setLoading(true)

        const res = await getMiniMasterRawDamAnalysisByScenario(
            pipelineId,
            selectedValue
        )

        if (res.error !== null) {
            showAlert('Failed to retrieve tables.  Please wait and try again.')
        } else {
            setVisualizations(res.data)
        }
        setLoading(false)
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={3}>
                <Typography>Choose a scenario to begin</Typography>
            </Grid>
            <Grid item xs={3}>
                <Select
                    disabled={loading}
                    value={selectedScenarioOption}
                    onChange={handleScenarioChange}
                    displayEmpty
                    fullWidth
                >
                    <MenuItem value="" disabled>
                        Scenario
                    </MenuItem>
                    {scenarioOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={4}>
                {loading && (
                    <div style={{ margin: '32px 0' }}>
                        <LinearProgress variant="indeterminate" />
                    </div>
                )}
            </Grid>

            <Grid item xs={12}>
                {visualizations.length > 0 &&
                    visualizations.map((elem, index) => (
                        <DataTable {...elem} />
                    ))}
            </Grid>
        </Grid>
    )
}

export default MiMRawDamAnalysis
