import { useEffect, useState, useCallback } from 'react'
import { getDAMAuctionMonthAndYear } from '../../../api'
import {
    FormControl,
    NativeSelect,
    InputLabel,
    Tooltip,
} from '@material-ui/core'

const DAMPostProcessingAuctionMonthAndYearWidget = (props) => {
    const { value, onChange, formContext } = props
    const [dates, setDates] = useState([])
    const [cachedAuctionSequence, setCachedAuctionSequence] = useState(null)

    const handleChange = useCallback(
        (month) => {
            onChange(month)
        },
        [onChange]
    )

    useEffect(() => {
        const asyncGetDAMAuctionMonthAndYear = async () => {
            let auctionSequence = formContext?.auctionSequence

            if (
                auctionSequence !== null &&
                auctionSequence !== undefined &&
                auctionSequence !== cachedAuctionSequence
            ) {
                setCachedAuctionSequence(auctionSequence)
                const res = await getDAMAuctionMonthAndYear(auctionSequence)
                if (res.error === null) {
                    setDates(res.data)
                    handleChange(undefined)
                } else {
                    setDates([])
                    handleChange(undefined)
                }
            }
        }

        asyncGetDAMAuctionMonthAndYear()
    }, [formContext.auctionSequence, handleChange, cachedAuctionSequence])

    const onSelected = (event) => {
        onChange(event.target.value)
    }

    return (
        <Tooltip title="Distinct list of auction month / auction year in crr annual auction model data">
            <FormControl>
                <InputLabel shrink={true}>
                    Auction Month / Auction Year
                </InputLabel>
                <NativeSelect onChange={onSelected} value={value}>
                    {dates.map((option) => {
                        return (
                            <option
                                key={option.month + '/' + option.year}
                                value={option.month + '/' + option.year}
                            >
                                {option.month + '/' + option.year}
                            </option>
                        )
                    })}
                </NativeSelect>
            </FormControl>
        </Tooltip>
    )
}
export default DAMPostProcessingAuctionMonthAndYearWidget
