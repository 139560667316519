import { useEffect, useState } from 'react'
import { getDAMLoopScenariosByPipeline } from '../../../api'
import {
    List,
    ListItem,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
} from '@material-ui/core'

const DAMPostProcessingScenarioWidget = (props) => {
    const { value, onChange, formContext } = props
    const [scenarios, setScenarios] = useState([])

    useEffect(() => {
        const asyncFetchGetDAMScenarios = async () => {
            let dataExtractionPipelineId = formContext?.dataExtractionPipelineId

            if (
                dataExtractionPipelineId !== null &&
                dataExtractionPipelineId !== undefined
            ) {
                const res = await getDAMLoopScenariosByPipeline(
                    dataExtractionPipelineId
                )
                setScenarios(res.data['scenarios'])
            } else {
                setScenarios([])
            }
        }

        asyncFetchGetDAMScenarios()
    }, [formContext?.dataExtractionPipelineId])

    const handleCheckboxChange = (event) => {
        const optionValue = parseInt(event.target.value, 10)
        const isChecked = event.target.checked

        let newSelectedOptions

        if (isChecked) {
            newSelectedOptions = [...value, optionValue]
        } else {
            newSelectedOptions = value.filter((val) => val !== optionValue)
        }

        onChange(newSelectedOptions)
    }

    return (
        <Grid>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Available scenarios:
            </Typography>
            <List>
                {scenarios.map((option) => (
                    <ListItem key={option}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value.includes(option)}
                                    onChange={handleCheckboxChange}
                                    value={option.toString()}
                                />
                            }
                            label={option.toString()}
                        />
                    </ListItem>
                ))}
            </List>
        </Grid>
    )
}
export default DAMPostProcessingScenarioWidget
