import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { ProtectedRoute } from './Auth0/protected-route'
import { theme } from './theme'
import home from './Home'
import PipelineList from './Pipelines/PipelineList'
import Pipeline from './Pipelines/Pipeline'
import PipelineGraph from './Pipelines/PipelineGraph'
import PipelineSettings from './PipelineSettings'
import PipelineSetting from './PipelineSettings/PipelineSetting'
import GlobalConfigurations from './GlobalConfigurations'
import ScraperStats from './Stats/ScraperStats'
import LPStats from './Stats/LPStats'
import { SocketProvider } from './WebSockets/SocketContext'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import { sendUserVerificationEmail } from './api'

const setupAxiosInterceptors = (getToken, logout) => {
    axios.interceptors.request.use(
        async (config) => {
            if (process.env.NODE_ENV === 'production') {
                config.url = `/api${config.url}`
            }
            try {
                const token = await getToken()
                config.headers.Authorization = `Bearer ${token}`
                return config
            } catch (error) {
                // If we can't get the refresh token force the user to log out
                logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                })
            }
        },
        (error) => {
            return Promise.reject(error)
        }
    )
}

const NoMatch = ({ location }) => (
    <div>
        <h3>
            No match for <code>{location.pathname}</code>
        </h3>
    </div>
)

const VerificationMessage = (props) => {
    const resendVerificationEmail = async () => {
        const res = await sendUserVerificationEmail(props.userId)
        if (res.error !== null) {
            console.error('Failed to send user verification')
        } else {
            console.log('User Verification Sent')
        }
    }

    return (
        <div
            style={{
                fontFamily: 'Arial, sans-serif',
                backgroundColor: '#f3f4f6',
                color: '#333',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                margin: '0',
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                    background: 'white',
                    padding: '30px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
            >
                <h1 style={{ color: '#d50037' }}>
                    Email Verification Required
                </h1>
                <p>
                    Please verify your email address to continue. Once verified,
                    logout and log back in.
                </p>
                <p>
                    <button
                        style={{
                            backgroundColor: '#572a31',
                            color: 'white',
                            border: 'none',
                            padding: '10px 20px',
                            fontSize: '16px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            marginTop: '20px',
                        }}
                        onClick={() =>
                            props.logout({
                                logoutParams: {
                                    returnTo: window.location.origin,
                                },
                            })
                        }
                    >
                        Logout
                    </button>
                </p>
                <p style={{ marginTop: '20px', color: '#555' }}>
                    Didn't receive the email?{' '}
                    <span
                        style={{
                            color: '#007bff',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}
                        onClick={resendVerificationEmail}
                    >
                        Resend verification email
                    </span>
                </p>
            </div>
        </div>
    )
}
const App = (props) => {
    const { getAccessTokenSilently, logout, user } = useAuth0()

    useEffect(() => {
        setupAxiosInterceptors(getAccessTokenSilently, logout)
    }, [getAccessTokenSilently, logout])

    if (user !== undefined && !user.email_verified) {
        return <VerificationMessage logout={logout} userId={user.sub} />
    }

    return (
        <ThemeProvider theme={theme}>
            <Switch>
                <ProtectedRoute path="/" exact component={home} />
                <Route path="/callback" exact component={home} />
                <ProtectedRoute
                    path="/run_configurations"
                    exact
                    render={(props) => (
                        <PipelineSettings
                            {...props}
                            settings_type={'run_configuration'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/run_modifications"
                    exact
                    render={(props) => (
                        <PipelineSettings
                            {...props}
                            settings_type={'run_modification'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/custom_auction_modifications"
                    exact
                    render={(props) => (
                        <PipelineSettings
                            {...props}
                            settings_type={'custom_auction_modification'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/global_configurations"
                    exact
                    render={(props) => <GlobalConfigurations {...props} />}
                />
                <ProtectedRoute
                    path="/scraper_stats"
                    exact
                    render={(props) => <ScraperStats {...props} />}
                />
                <ProtectedRoute
                    path="/lp_stats"
                    exact
                    render={(props) => <LPStats {...props} />}
                />
                <ProtectedRoute
                    path="/data_transformation_pipeline"
                    exact
                    render={(props) => (
                        <PipelineList
                            {...props}
                            pipeline_name={'data_transformation_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/data_transformation_pipeline/graph"
                    exact
                    render={(props) => (
                        <PipelineGraph
                            {...props}
                            pipeline_name={'data_transformation_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/data_transformation_pipeline/create"
                    exact
                    render={(props) => (
                        <Pipeline
                            {...props}
                            pipeline_name={'data_transformation_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/data_transformation_pipeline/create/scenarios/:id"
                    exact
                    render={(props) => (
                        <Pipeline
                            {...props}
                            pipeline_name={'data_transformation_pipeline'}
                            creatingScenarios={true}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/data_transformation_pipeline/:id"
                    exact
                    render={(props) => (
                        <SocketProvider>
                            <Pipeline
                                {...props}
                                pipeline_name={'data_transformation_pipeline'}
                            />
                        </SocketProvider>
                    )}
                />
                <ProtectedRoute
                    path="/data_extraction_pipeline"
                    exact
                    render={(props) => (
                        <PipelineList
                            {...props}
                            pipeline_name={'data_extraction_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/data_extraction_pipeline/graph"
                    exact
                    render={(props) => (
                        <PipelineGraph
                            {...props}
                            pipeline_name={'data_extraction_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/data_extraction_pipeline/create"
                    exact
                    render={(props) => (
                        <Pipeline
                            {...props}
                            pipeline_name={'data_extraction_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/data_extraction_pipeline/:id"
                    exact
                    render={(props) => (
                        <SocketProvider>
                            <Pipeline
                                {...props}
                                pipeline_name={'data_extraction_pipeline'}
                            />
                        </SocketProvider>
                    )}
                />
                <ProtectedRoute
                    path="/dam_post_processing_pipeline"
                    exact
                    render={(props) => (
                        <PipelineList
                            {...props}
                            pipeline_name={'dam_post_processing_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/dam_post_processing_pipeline/graph"
                    exact
                    render={(props) => (
                        <PipelineGraph
                            {...props}
                            pipeline_name={'dam_post_processing_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/dam_post_processing_pipeline/create"
                    exact
                    render={(props) => (
                        <Pipeline
                            {...props}
                            pipeline_name={'dam_post_processing_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/dam_post_processing_pipeline/:id"
                    exact
                    render={(props) => (
                        <SocketProvider>
                            <Pipeline
                                {...props}
                                pipeline_name={'dam_post_processing_pipeline'}
                            />
                        </SocketProvider>
                    )}
                />
                <ProtectedRoute
                    path="/auction_pipeline"
                    exact
                    render={(props) => (
                        <PipelineList
                            {...props}
                            pipeline_name={'auction_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/auction_pipeline/graph"
                    exact
                    render={(props) => (
                        <PipelineGraph
                            {...props}
                            pipeline_name={'auction_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/auction_pipeline/create"
                    exact
                    render={(props) => (
                        <Pipeline
                            {...props}
                            pipeline_name={'auction_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/auction_pipeline/:id"
                    exact
                    render={(props) => (
                        <SocketProvider>
                            <Pipeline
                                {...props}
                                pipeline_name={'auction_pipeline'}
                            />
                        </SocketProvider>
                    )}
                />
                <ProtectedRoute
                    path="/auction_ii_pipeline"
                    exact
                    render={(props) => (
                        <PipelineList
                            {...props}
                            pipeline_name={'auction_ii_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/auction_ii_pipeline/graph"
                    exact
                    render={(props) => (
                        <PipelineGraph
                            {...props}
                            pipeline_name={'auction_ii_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/auction_ii_pipeline/create"
                    exact
                    render={(props) => (
                        <Pipeline
                            {...props}
                            pipeline_name={'auction_ii_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/auction_ii_pipeline/:id"
                    exact
                    render={(props) => (
                        <SocketProvider>
                            <Pipeline
                                {...props}
                                pipeline_name={'auction_ii_pipeline'}
                            />
                        </SocketProvider>
                    )}
                />
                <ProtectedRoute
                    path="/auction_model_pipeline"
                    exact
                    render={(props) => (
                        <PipelineList
                            {...props}
                            pipeline_name={'auction_model_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/auction_model_pipeline/graph"
                    exact
                    render={(props) => (
                        <PipelineGraph
                            {...props}
                            pipeline_name={'auction_model_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/auction_model_pipeline/create"
                    exact
                    render={(props) => (
                        <Pipeline
                            {...props}
                            pipeline_name={'auction_model_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/auction_model_pipeline/:id"
                    exact
                    render={(props) => (
                        <SocketProvider>
                            <Pipeline
                                {...props}
                                pipeline_name={'auction_model_pipeline'}
                            />
                        </SocketProvider>
                    )}
                />
                <ProtectedRoute
                    path="/master_model_data_prep_pipeline"
                    exact
                    render={(props) => (
                        <PipelineList
                            {...props}
                            pipeline_name={'master_model_data_prep_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/master_model_data_prep_pipeline/graph"
                    exact
                    render={(props) => (
                        <PipelineGraph
                            {...props}
                            pipeline_name={'master_model_data_prep_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/master_model_data_prep_pipeline/create"
                    exact
                    render={(props) => (
                        <Pipeline
                            {...props}
                            pipeline_name={'master_model_data_prep_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/master_model_data_prep_pipeline/:id"
                    exact
                    render={(props) => (
                        <SocketProvider>
                            <Pipeline
                                {...props}
                                pipeline_name={
                                    'master_model_data_prep_pipeline'
                                }
                            />
                        </SocketProvider>
                    )}
                />
                <ProtectedRoute
                    path="/mini_master_pipeline"
                    exact
                    render={(props) => (
                        <PipelineList
                            {...props}
                            pipeline_name={'mini_master_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/mini_master_pipeline/graph"
                    exact
                    render={(props) => (
                        <PipelineGraph
                            {...props}
                            pipeline_name={'mini_master_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/mini_master_pipeline/create"
                    exact
                    render={(props) => (
                        <Pipeline
                            {...props}
                            pipeline_name={'mini_master_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/mini_master_pipeline/:id"
                    exact
                    render={(props) => (
                        <SocketProvider>
                            <Pipeline
                                {...props}
                                pipeline_name={'mini_master_pipeline'}
                            />
                        </SocketProvider>
                    )}
                />
                <ProtectedRoute
                    path="/portfolio_stats_pipeline"
                    exact
                    render={(props) => (
                        <PipelineList
                            {...props}
                            pipeline_name={'portfolio_stats_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/portfolio_stats_pipeline/graph"
                    exact
                    render={(props) => (
                        <PipelineGraph
                            {...props}
                            pipeline_name={'portfolio_stats_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/portfolio_stats_pipeline/create"
                    exact
                    render={(props) => (
                        <Pipeline
                            {...props}
                            pipeline_name={'portfolio_stats_pipeline'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/portfolio_stats_pipeline/:id"
                    exact
                    render={(props) => (
                        <SocketProvider>
                            <Pipeline
                                {...props}
                                pipeline_name={'portfolio_stats_pipeline'}
                            />
                        </SocketProvider>
                    )}
                />
                <ProtectedRoute
                    path="/run_configuration/create"
                    exact
                    render={(props) => (
                        <PipelineSetting
                            {...props}
                            settings_type={'run_configuration'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/run_configuration/:id"
                    exact
                    render={(props) => (
                        <PipelineSetting
                            {...props}
                            settings_type={'run_configuration'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/run_modification/create"
                    exact
                    render={(props) => (
                        <PipelineSetting
                            {...props}
                            settings_type={'run_modification'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/run_modification/:id"
                    exact
                    render={(props) => (
                        <PipelineSetting
                            {...props}
                            settings_type={'run_modification'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/custom_auction_modification/create"
                    exact
                    render={(props) => (
                        <PipelineSetting
                            {...props}
                            settings_type={'custom_auction_modification'}
                        />
                    )}
                />
                <ProtectedRoute
                    path="/custom_auction_modification/:id"
                    exact
                    render={(props) => (
                        <PipelineSetting
                            {...props}
                            settings_type={'custom_auction_modification'}
                        />
                    )}
                />
                <ProtectedRoute component={NoMatch} />
            </Switch>
        </ThemeProvider>
    )
}

export default App
