import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { getAuctionModelMatchingPredecessorPipelines } from '../../../api'

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: 'flex',
    },
}))

const AuctionModelPipelineSelect = (props) => {
    const { onChange, formContext } = props
    const [auctionPipelines, setAuctionPipelines] = useState([])

    const [pipeline, setPipeline] = useState('')

    const classes = useStyles()

    const handleChange = (event) => {
        let pipeline = event.target.value
        setPipeline(pipeline)
        onChange(pipeline)
    }

    useEffect(() => {
        const getAuctionModelPipelines = async () => {
            let auctionIIPipelineId =
                formContext.auctionModelPipelineSettings?.auctionIIPipelineId
            if (
                auctionIIPipelineId !== null &&
                auctionIIPipelineId !== undefined
            ) {
                const res =
                    await getAuctionModelMatchingPredecessorPipelines(
                        auctionIIPipelineId
                    )
                if (res.error !== null) {
                    // TODO: Figure how to inject errors into RSJF validation
                    console.error('Error retrieving auction model rounds.')
                } else {
                    setAuctionPipelines(res.data)
                }
            }
        }

        getAuctionModelPipelines().catch(console.error)
    }, [formContext.auctionModelPipelineSettings?.auctionIIPipelineId])
    return (
        <div>
            {auctionPipelines.length >= 1 ? (
                <FormControl className={classes.formControl}>
                    <InputLabel id="inherit-auction-model-select-label">
                        Re-run from Former Auction Model
                    </InputLabel>
                    <Select
                        labelId="inherit-auction-model-select-label"
                        id="inherit-auction-model-select"
                        value={pipeline}
                        onChange={handleChange}
                    >
                        {auctionPipelines.map((pipelineId) => (
                            <MenuItem value={pipelineId} key={pipelineId}>
                                {pipelineId.toString()}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <div>No Auction Model Pipelines available to choose</div>
            )}
        </div>
    )
}

export default AuctionModelPipelineSelect
