import { Component } from 'react'
import { Grid, LinearProgress, Typography } from '@material-ui/core'
import { SocketContext } from '../../WebSockets/SocketContext'

class PipelineDeletionWidget extends Component {
    static contextType = SocketContext

    constructor(props) {
        super(props)
        this.state = {
            stopped: false,
            deletionStatus: '',
            deletionMessage: '',
        }
        this.handleWebSocket = this.handleWebSocket.bind(this)
    }

    async componentDidMount() {
        this.setState({
            deletionMessage: 'Deletion in progress.',
            deletionStatus: 'in-progress',
        })
    }

    async componentDidUpdate(prevState) {
        if (this.state.deletionStatus !== prevState.deletionStatus) {
            await this.handleWebSocket()
        }
    }

    componentWillUnmount() {
        const socket = this.context
        if (socket) {
            socket.off('deletion_status')
        }
    }

    async handleWebSocket() {
        const socket = this.context
        if (socket) {
            socket.onMessage('deletion_status', (incomingData) => {
                if (this.props.executionId === incomingData.execution_id) {
                    this.setState({
                        deletionMessage: incomingData.data.message,
                        deletionStatus: incomingData.status,
                    })
                }
            })
        }
    }

    render() {
        return (
            <Grid
                container
                spacing={6}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                {!this.state.stopped && (
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            {this.state.deletionMessage}
                        </Typography>
                        {this.state.deletionStatus === 'in-progress' && (
                            <LinearProgress />
                        )}
                    </Grid>
                )}
            </Grid>
        )
    }
}

export default PipelineDeletionWidget
