import Layout from '../Layout'
import {
    Button,
    Typography,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Snackbar,
    CircularProgress,
    IconButton,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add'
import MuiAlert from '@material-ui/lab/Alert'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { SearchableSelect } from '../SearchableSelect/SearchableSelect'
import { convertToLocalTime } from '../Utils/TimeUtil'

const useStyles = makeStyles((theme) => ({
    uploadFileInput: {
        float: 'right',
        textTransform: 'unset',
    },
    gridItemSearch: {
        width: '100%',
    },
    gridItem: {
        padding: '.75rem',
        width: '100%',
        margin: 'auto',
    },
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    sectionHeader: {
        padding: '1.2rem 0',
        display: 'flex',
        flexWrap: 'nowrap',
        position: 'static',
        margin: 'auto',
    },
}))

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const InfoPanel = (props) => {
    const { children, value, index } = props

    return (
        <div>
            {value === index && (
                <Typography component={'span'}>{children}</Typography>
            )}
        </div>
    )
}

const GlobalConfigurations = (props) => {
    const [alertOpen, setAlertOpen] = useState(null)
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(null)
    const [globalConfigurations, setGlobalConfigurations] = useState([])
    const [selectedValue, setSelectedValue] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [selectedClassName, setSelectedClassName] = useState(null)
    const [selectedInfo, setSelectedInfo] = useState([])
    const classes = useStyles()

    useEffect(() => {
        const getSuggestions = async () => {
            let res = await axios.get(`/global_configurations/list`)
            if (res.status !== 200) {
                setGlobalConfigurations([])
            } else {
                if (res.data.length === 0) {
                    showAlert('No global configurations found')
                } else {
                    setGlobalConfigurations(res.data)
                }
            }
        }
        getSuggestions()
    }, [setGlobalConfigurations])

    const handleChange = async (event) => {
        setLoading(true)
        const index = event.target.value
        if (index === undefined) {
            setSelectedName(null)
            setSelectedClassName(null)
            setSelectedValue(null)
        } else {
            setSelectedName(globalConfigurations[index].friendly_name)
            setSelectedClassName(globalConfigurations[index].name)
            setSelectedValue(Number(globalConfigurations[index].id))
            const results = await axios.get(
                `/global_configurations/${globalConfigurations[index].name}`
            )
            setSelectedInfo(results.data)
        }
        setLoading(false)
    }

    const handleFileDownloadClick = async (id, filename) => {
        const res = await axios.get(
            `/global_configurations/file/${selectedClassName}/${id}`
        )
        const downloadURL = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = downloadURL
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
    }

    const handleBlankFileDownloadClick = async () => {
        const res = await axios.get(
            `/global_configurations/file/${selectedClassName}`
        )

        const filename = selectedName.replace(/\s/g, '_').toLowerCase() + '.csv'
        const downloadURL = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = downloadURL
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
    }

    const handleFileUploadChange = (event) => {
        const file = event.target.files[0]

        ingestRsyFile(file)
    }

    const ingestRsyFile = async (file) => {
        const { history } = props

        setLoading(true)

        var formData = new FormData()
        formData.append('class_name', selectedClassName)
        formData.append('file_name', file.name)
        formData.append(file.name, file, file.name)

        try {
            const res = await axios({
                method: 'POST',
                url: `/global_configurations/create`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
            })
            if (res.status === 200) {
                history.go(0)
            }
        } catch (e) {
            showAlert(
                e +
                    '. Could not ingest global configuration file. Ensure file data matches the database table. View logs for more details.'
            )
            setLoading(false)
        }
    }

    const showAlert = (message) => {
        setAlertOpen(true)
        setAlertMessage(message)
    }

    return (
        <Layout>
            <div style={{ marginBottom: '2rem' }}>
                <Typography component="div" className={classes.sectionHeader}>
                    <Typography variant="h5" gutterBottom>
                        Global Configurations
                    </Typography>
                </Typography>
                <Grid container>
                    <Grid item xs={6} className={classes.gridItemSearch}>
                        <SearchableSelect
                            key={selectedValue}
                            label="Select Global Configuration"
                            value={selectedValue}
                            onChange={handleChange}
                            options={globalConfigurations.slice(1)}
                            keyPropFn={(option) => option.id}
                            valuePropFn={(option) => option.friendly_name}
                            showAll
                            removeSelectionText
                            alwaysFocusTextFieldOnEnter
                        />
                    </Grid>
                    {selectedValue !== null && (
                        <Grid item xs={3} className={classes.gridItem}>
                            <Button
                                variant="outlined"
                                component="label"
                                color="primary"
                                className={classes.uploadFileInput}
                                startIcon={<CloudDownloadIcon />}
                                onClick={async () => {
                                    await handleBlankFileDownloadClick()
                                }}
                            >
                                DOWNLOAD BLANK CSV
                                <br />
                                {selectedName}
                            </Button>
                        </Grid>
                    )}
                    {selectedValue !== null && (
                        <Grid item xs={3} className={classes.gridItem}>
                            <Button
                                variant="outlined"
                                component="label"
                                color="primary"
                                className={classes.uploadFileInput}
                                startIcon={<AddIcon />}
                            >
                                UPLOAD NEW
                                <br />
                                {selectedName}
                                <input
                                    onChange={handleFileUploadChange}
                                    type="file"
                                    hidden
                                />
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.gridItem}>
                        <InfoPanel
                            value={selectedValue}
                            index={selectedValue}
                            key={selectedValue}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Filename</TableCell>
                                        <TableCell>Timestamp</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                {selectedValue !== null &&
                                    selectedInfo.length > 0 &&
                                    !loading && (
                                        <TableBody>
                                            {selectedInfo.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {row.filename}
                                                    </TableCell>
                                                    <TableCell>
                                                        {convertToLocalTime(
                                                            row.timestamp
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={async () => {
                                                                await handleFileDownloadClick(
                                                                    row.id,
                                                                    row.filename
                                                                )
                                                            }}
                                                        >
                                                            <CloudDownloadIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    )}
                            </Table>
                        </InfoPanel>
                    </Grid>
                    {loading && (
                        <div className={classes.loadingIndicator}>
                            <CircularProgress color="primary" size={75} />
                        </div>
                    )}
                    {selectedValue !== null &&
                        selectedInfo &&
                        selectedInfo.length <= 0 &&
                        !loading && (
                            <Typography
                                component="div"
                                className={classes.sectionHeader}
                            >
                                <Typography variant="h5" gutterBottom>
                                    {
                                        'No Global Configurations found. Upload a new one!'
                                    }
                                </Typography>
                            </Typography>
                        )}
                    {selectedValue === null && !loading && (
                        <Typography
                            component="div"
                            className={classes.sectionHeader}
                        >
                            <Typography variant="h5" gutterBottom>
                                {'No Global Configuration selected yet...'}
                            </Typography>
                        </Typography>
                    )}
                </Grid>
            </div>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={(e, r) => setAlertOpen(false)}
            >
                <Alert onClose={(e, r) => setAlertOpen(false)} severity="error">
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Layout>
    )
}
export default GlobalConfigurations
