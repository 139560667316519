export function convertToLocalTime(timestamp) {
    const timezoneOffset = new Date().getTimezoneOffset()
    const localTimestamp = new Date(timestamp)
    localTimestamp.setMinutes(localTimestamp.getMinutes() - timezoneOffset)
    const timezoneAbbreviation = getAbbreviation()
    return localTimestamp.toLocaleString() + ' ' + timezoneAbbreviation
}

function getAbbreviation() {
    var zone = new Date()
        .toLocaleTimeString('en-us', { timeZoneName: 'short' })
        .split(' ')[2]
    return zone
}
