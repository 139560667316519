import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PipelineAlert from '../PipelineAlert'
import { ListItem } from '@material-ui/core'

const TimelineModal = ({ intervalTimelineData, onCloseModal }) => {
    const isModalOpen = intervalTimelineData !== null

    const handleClose = () => {
        onCloseModal()
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Timeline</DialogTitle>
                {intervalTimelineData !== null && (
                    <DialogContent style={{ height: '50%' }}>
                        {intervalTimelineData.map((entry) => (
                            <ListItem key={entry.id}>
                                <PipelineAlert alert={entry} />
                            </ListItem>
                        ))}
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default TimelineModal
