import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'
import { Route } from 'react-router-dom'
import { PageLoader } from './page-loader'

export const ProtectedRoute = ({ component, render, ...args }) => {
    const renderComponent = component ? component : render
    return (
        <Route
            {...args}
            render={(props) => (
                <RouteHandler
                    renderComponent={renderComponent}
                    withAuthenticationArgs={{
                        onRedirecting: () => (
                            <div className="page-layout">
                                <PageLoader />
                            </div>
                        ),
                    }}
                    {...props}
                />
            )}
        />
    )
}

const RouteHandler = ({
    renderComponent,
    withAuthenticationArgs,
    ...props
}) => {
    const Component = withAuthenticationRequired(
        renderComponent,
        withAuthenticationArgs
    )
    return <Component {...props} />
}
