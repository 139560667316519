import { Tooltip, Button, CircularProgress } from '@material-ui/core'
import Add from '@material-ui/icons/AddToPhotos'

const AddRunScenario = ({ status, handleAction, disabled = false }) => {
    if (status === 'Canceling') {
        return (
            <Tooltip title={status}>
                <CircularProgress size={24} />
            </Tooltip>
        )
    }

    return (
        <Tooltip title={'Add pipeline run scenarios'}>
            <Button
                disabled={disabled}
                component="label"
                color="primary"
                startIcon={<Add />}
                onClick={(event) => {
                    handleAction(event, 'add scenarios')
                }}
            ></Button>
        </Tooltip>
    )
}

export default AddRunScenario
