import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
    palette: {
        primary: {
            light: '#ff5261',
            main: '#d50037',
            dark: '#9b0012',
            contrastText: '#fff',
        },
        secondary: {
            light: '#85545a',
            main: '#572a31',
            dark: '#2c0108',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Lato',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightBold: 700,
        subtitle1: {
            fontSize: 12,
        },
    },
})
