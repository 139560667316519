import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import App from './App'
import { createBrowserHistory } from 'history'
import { Auth0ProviderWithHistory } from './Auth0/auth0-provider-with-history'

const history = createBrowserHistory({ basename: '/' })

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <App history={history} />
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
)
