import io from 'socket.io-client'

export class WebSocketWithInterceptors {
    constructor(url, options) {
        this.url = url
        this.socket = io(url, options)
    }

    onConnect(callback) {
        this.socket.on('connect', callback)
    }

    onDisconnect(callback) {
        this.socket.on('disconnect', callback)
    }

    onMessage(event, callback) {
        this.socket.on(event, callback)
    }

    emit(event, data = '') {
        if (data !== '') {
            this.socket.emit(event, data)
        } else {
            this.socket.emit(event)
        }
    }

    disconnect() {
        this.socket.disconnect()
    }

    off(event) {
        this.socket.off(event)
    }
}
