import { Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import Autocomplete from '../Autocomplete'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    gridItem: {
        width: '100%',
        paddingLeft: '4.5rem',
    },
    searchItem: {
        width: '100%',
    },
    textField: {
        display: 'flex',
    },
}))

const SlackBusNameAuctionSearch = (props) => {
    const classes = useStyles()

    const { onChange, options, formContext } = props

    const [slackBusNameValue, setSlackBusNameValue] = useState(null)
    const [busNumGams, setBusNumGams] = useState(null)
    const [pipelineIdFeasibilityTestModel, setPipelineIdFeasibilityTestModel] =
        useState(null)
    const [slackBusNameSearchResults, setSlackBusNameSearchResults] = useState(
        []
    )

    const handleChange = (event, selection) => {
        let selectedSlackBusNameValue = get(selection.suggestion, 'name')
        let selectedBusNumGamsValue = get(selection.suggestion, 'number')
        setSlackBusNameValue(selectedSlackBusNameValue)
        setBusNumGams(selectedBusNumGamsValue)
        event.preventDefault()
        onChange(selectedSlackBusNameValue)
    }

    useEffect(() => {
        let changedPipelineIdFeasibilityTestModel = get(
            formContext?.auctionIIPipelineSettings,
            'pipelineIdFeasibilityTestModel',
            null
        )
        setPipelineIdFeasibilityTestModel(changedPipelineIdFeasibilityTestModel)
        if (itemInvalid(changedPipelineIdFeasibilityTestModel)) {
            setSlackBusNameValue(null)
            setBusNumGams(null)
            setPipelineIdFeasibilityTestModel(null)
            onChange(null)
        }
    }, [
        setPipelineIdFeasibilityTestModel,
        formContext?.auctionIIPipelineSettings,
        onChange,
    ])

    const itemInvalid = (params) => {
        return params === null || params === '' || params === undefined
    }

    const getSuggestionDisplayValue = (suggestion) => {
        return suggestion.name
    }

    const getSuggestions = async (term) => {
        setSlackBusNameValue(null)
        setBusNumGams(null)
        onChange(null)

        if (term.length >= 2) {
            var pipelineId
            setPipelineIdFeasibilityTestModel((currentState) => {
                pipelineId = currentState
                return currentState
            })
            let res = await axios.get(
                `/pipeline/get_slack_bus_names_auction?term=${term}&id=${pipelineId}`
            )
            if (res.status !== 200) {
                setSlackBusNameSearchResults([])
            }
            setSlackBusNameSearchResults(res.data)
        }
    }

    const renderSuggestion = (suggestion, { query, isHighlighted }) => {
        return (
            <MenuItem
                selected={isHighlighted}
                component="div"
                style={{ width: '100%' }}
            >
                <Typography variant="body2">{suggestion.name}</Typography>
            </MenuItem>
        )
    }

    return (
        <Grid container alignItems="flex-start" direction="row">
            <Autocomplete
                placeholder={options.title}
                disabled={itemInvalid(pipelineIdFeasibilityTestModel)}
                getSuggestions={getSuggestions}
                getSuggestionValue={getSuggestionDisplayValue}
                suggestions={slackBusNameSearchResults}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={(event, suggestion) =>
                    handleChange(event, suggestion)
                }
                inputValue={get(slackBusNameValue, '')}
            />
            <Grid item xs={6} className={classes.gridItem}>
                <TextField
                    className={classes.textField}
                    variant="outlined"
                    label="Bus Number GAMS"
                    disabled={true}
                    value={busNumGams || ''}
                />
            </Grid>
        </Grid>
    )
}
export default SlackBusNameAuctionSearch
