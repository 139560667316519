import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core'

import { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import Editor from '@monaco-editor/react'
import DAMPipelineScenarioSelect from '../DAMPipelineScenarioSelect'

const CreateDAMLoopScenario = (props) => {
    const {
        intervalCount,
        onDataUpdated,
        monthlyAuction,
        pipelineId,
        defaultAdvancedSettings,
        preProcessInputHook,
        preProcessModelHook,
        postProcessModelHook,
        onDefaultAdvancedSettingsChanged,
        onPreProcessInputHookChanged,
        onPreProcessModelHookChanged,
        onPostProcessModelHookChanged,
    } = props
    const [customDAMLoopArtifactsFilename, setCustomDAMLoopArtifactsFilename] =
        useState(null)
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

    const [damLoopData, setDamLoopData] = useState({
        name: 'New Scenario',
        damLoopIntervals: [],
        customDAMLoopArtifacts: null,
        pipelineId: pipelineId,
        damPipelineScenarioId: null,
    })

    function initializeDefaultDAMLoopScenario() {
        const defaultIntervalSettings = []
        for (let i = 1; i <= intervalCount; i++) {
            defaultIntervalSettings.push({
                interval: i,
                maxContingencies: monthlyAuction ? 15 : 25,
                maxNewLines: monthlyAuction ? 2 : 4,
                maxRounds: -1,
                start: true,
            })
        }
        const updatedDAMLoopData = {
            ...damLoopData,
            damLoopIntervals: defaultIntervalSettings,
        }
        setDamLoopData(updatedDAMLoopData)
        onDataUpdated(updatedDAMLoopData)
    }

    useEffect(() => {
        initializeDefaultDAMLoopScenario()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onScenarioNameChange = (e) => {
        const value = e.target.value

        // Don't allow empty values in our form
        if (value === null || value === undefined || value === '') return

        const updatedDAMLoopData = {
            ...damLoopData,
            name: value,
        }

        setDamLoopData(updatedDAMLoopData)
        onDataUpdated(updatedDAMLoopData)
    }

    const onDamPipelineScenarioIdChange = (value) => {
        const scenarioId = value

        // Don't allow empty values in our form
        if (
            scenarioId === null ||
            scenarioId === undefined ||
            scenarioId === ''
        )
            return

        const updatedDAMLoopData = {
            ...damLoopData,
            damPipelineScenarioId: scenarioId,
        }

        setDamLoopData(updatedDAMLoopData)
        onDataUpdated(updatedDAMLoopData)
    }

    const onIntervalSettingsChange = (value, field, interval) => {
        // Don't allow empty values in our form
        if (value === null || value === undefined || value === '') return

        const updatedIntervalSettings = damLoopData.damLoopIntervals.map(
            (intervalSetting) => {
                if (intervalSetting.interval === interval) {
                    return { ...intervalSetting, [field]: value }
                }
                return intervalSetting
            }
        )

        // If the interval doesn't exist, add the new interval settings to the array
        const intervalExists = damLoopData.damLoopIntervals.some(
            (intervalSetting) => {
                return intervalSetting.interval === interval
            }
        )

        if (!intervalExists) {
            updatedIntervalSettings.push({
                interval: interval,
                [field]: value,
            })
        }

        const updatedDAMLoopData = {
            ...damLoopData,
            damLoopIntervals: updatedIntervalSettings,
        }

        setDamLoopData(updatedDAMLoopData)
        onDataUpdated(updatedDAMLoopData)
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0]

        if (file) {
            const reader = new FileReader()

            reader.onload = (e) => {
                const fileData = e.target.result
                // Convert the fileData to a byte string if needed
                // For example, if you need a base64-encoded byte string:
                let updatedDAMLoopData = { ...damLoopData }
                updatedDAMLoopData = {
                    ...damLoopData,
                    customDAMLoopArtifacts: fileData,
                }
                setCustomDAMLoopArtifactsFilename(file.name)

                setDamLoopData(updatedDAMLoopData)
                onDataUpdated(updatedDAMLoopData)
            }

            reader.readAsArrayBuffer(file)
        }
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="flex-start"
        >
            <Grid item xs={12}>
                <Typography>Create DAM Loop Scenario</Typography>
            </Grid>
            <Grid container direction="row" alignItems="flex-end">
                <Grid item xs={2}>
                    <TextField
                        label="Scenario Name"
                        onChange={onScenarioNameChange}
                        value={damLoopData.name}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DAMPipelineScenarioSelect
                        onChange={onDamPipelineScenarioIdChange}
                        pipelineId={pipelineId}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="outlined"
                        component="label"
                        color="primary"
                        startIcon={<AddIcon />}
                    >
                        Modified Artifacts
                        <input
                            id="customDAMLoopArtifacts"
                            onChange={handleFileChange}
                            type="file"
                            accept=".zip"
                            hidden
                        />
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <Typography>{customDAMLoopArtifactsFilename}</Typography>
                </Grid>
            </Grid>
            <br />
            {damLoopData.damLoopIntervals.map((intervalSetting) => (
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    key={intervalSetting.interval}
                >
                    <Grid item xs={2}>
                        <TextField
                            type="number"
                            placeholder={'Interval ' + intervalSetting.interval}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="maxContingencies"
                            label="Max Contingencies"
                            type="number"
                            value={intervalSetting.maxContingencies}
                            onChange={(event) =>
                                onIntervalSettingsChange(
                                    event.target.value,
                                    'maxContingencies',
                                    intervalSetting.interval
                                )
                            }
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="maxNewLines"
                            label="Max New Lines"
                            type="number"
                            value={intervalSetting.maxNewLines}
                            onChange={(event) =>
                                onIntervalSettingsChange(
                                    event.target.value,
                                    'maxNewLines',
                                    intervalSetting.interval
                                )
                            }
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Max Contingency Calculation Rounds.  Negative 1 indicates rounds will run until finished.">
                            <TextField
                                id="maxRounds"
                                label="Max Rounds"
                                type="number"
                                value={intervalSetting.maxRounds}
                                onChange={(event) =>
                                    onIntervalSettingsChange(
                                        event.target.value,
                                        'maxRounds',
                                        intervalSetting.interval
                                    )
                                }
                                InputProps={{ inputProps: { min: -1 } }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <FormControlLabel
                            label="Start"
                            control={
                                <Checkbox
                                    checked={Boolean(intervalSetting.start)}
                                    onChange={(event) =>
                                        onIntervalSettingsChange(
                                            !intervalSetting.start,
                                            'start',
                                            intervalSetting.interval
                                        )
                                    }
                                />
                            }
                        />
                    </Grid>
                    <Divider style={{ width: '100%' }} />
                </Grid>
            ))}
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showAdvancedOptions}
                                onChange={() => {
                                    setShowAdvancedOptions(!showAdvancedOptions)
                                }}
                                value={showAdvancedOptions}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography>Show Advanced Settings</Typography>
                </Grid>
                {showAdvancedOptions && (
                    <>
                        <Grid item>
                            <Typography>
                                Advanced Configuration (YAML)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                height="350px"
                                language="yaml"
                                theme="vs-light"
                                value={defaultAdvancedSettings}
                                onChange={(value) => {
                                    onDefaultAdvancedSettingsChanged(value)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Pre-Process Input Hook</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                height="250px"
                                language="python"
                                theme="vs-light"
                                value={preProcessInputHook}
                                onChange={(value) => {
                                    onPreProcessInputHookChanged(value)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Pre-Process Model Hook</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                height="250px"
                                language="python"
                                theme="vs-light"
                                value={preProcessModelHook}
                                onChange={(value) => {
                                    onPreProcessModelHookChanged(value)
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Post-Process Model Hook</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Editor
                                height="250px"
                                language="python"
                                theme="vs-light"
                                value={postProcessModelHook}
                                onChange={(value) => {
                                    onPostProcessModelHookChanged(value)
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default CreateDAMLoopScenario
