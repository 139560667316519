import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import Autocomplete from '../Autocomplete'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import {
    getDAM3Scenarios,
    getFlowDecompContingencySuggestions,
    getFlowDecompConstraintSuggestions,
    startFlowDecompRun,
} from '../../../api'
import {
    Grid,
    LinearProgress,
    MenuItem,
    Typography,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    ListItemSecondaryAction,
    Checkbox,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
    gridItem: {
        width: '100%',
        paddingRight: '.5rem',
    },
    searchItem: {
        width: '100%',
    },
}))

const DAM3FDOnDemandStarter = (props) => {
    const { pipelineId, showAlert } = props
    const classes = useStyles()

    // General states
    const [loading, setLoading] = useState(false)

    const [constraintsToSend, setConstraintsToSend] = useState([])

    const [startButtonStatus, setStartButtonStatus] = useState(true)

    // States of Checkbox list
    const [scenarioOptions, setScenarioOptions] = useState([])
    const [scenariosSelected, setScenariosSelected] = useState([])

    // States for the Autocomplete of Constraint
    const [constraintSelection, setConstraintSelection] = useState(null)
    const [constraintSearchResults, setConstraintSearchResults] = useState([])

    // States for the Autocomplete of Contingency
    const [contingencySelection, setContingencySelection] = useState(null)
    const [contingencySearchResults, setContingencySearchResults] = useState([])

    // Functions for list of check box
    const handleToggleScenario = (value) => () => {
        const currentIndex = scenariosSelected.indexOf(value)
        const res = [...scenariosSelected]
        if (currentIndex === -1) {
            res.push(value)
        } else {
            res.splice(currentIndex, 1)
        }
        setScenariosSelected(res)
    }

    // Functions for Autocomplete of Constraint
    const renderConstraintSuggestion = (
        suggestion,
        { query, isHighlighted }
    ) => {
        return (
            <MenuItem
                selected={isHighlighted}
                component="div"
                style={{ width: '100%' }}
            >
                <Typography variant="body2">
                    {suggestion.constraint_name} (rho: {suggestion.rho})
                </Typography>
            </MenuItem>
        )
    }
    const getConstraintSuggestions = async (term) => {
        setConstraintSelection(null)
        setLoading(true)
        if (term.length >= 2) {
            try {
                const res = await getFlowDecompConstraintSuggestions(
                    pipelineId,
                    term
                )
                if (res.error !== null) {
                    showAlert('Failed to return all the constraints.')
                    setConstraintSearchResults([])
                } else {
                    if (res.data.length === 0) {
                        setConstraintSearchResults([])
                        showAlert('No constraint suggestions for ' + term)
                    } else {
                        setConstraintSearchResults(res.data)
                    }
                }
            } catch (error) {
                setConstraintSearchResults([])
                showAlert('Failed to return all the constraints.')
                console.error('Error fetching options:', error)
            }
        }
        setLoading(false)
    }
    const handleChangeConstraintAutocomplete = (event, selection) => {
        setConstraintSelection(selection.suggestion)
    }

    // Functions for Autocomplete of Contingency
    const renderContingencySuggestion = (
        suggestion,
        { query, isHighlighted }
    ) => {
        return (
            <MenuItem
                selected={isHighlighted}
                component="div"
                style={{ width: '100%' }}
            >
                <Typography variant="body2">
                    {suggestion.contingency_name} (gamma: {suggestion.tau})
                </Typography>
            </MenuItem>
        )
    }
    const getContingencySuggestions = async (term) => {
        setContingencySelection(null)
        setLoading(true)
        if (term.length >= 2) {
            try {
                const res = await getFlowDecompContingencySuggestions(
                    pipelineId,
                    term
                )
                if (res.error !== null) {
                    showAlert('Failed to return all the contingencies.')
                    setContingencySearchResults([])
                } else {
                    if (res.data.length === 0) {
                        setContingencySearchResults([])
                        showAlert('No contingency suggestions for ' + term)
                    } else {
                        setContingencySearchResults(res.data)
                    }
                }
            } catch (error) {
                setContingencySearchResults([])
                showAlert('Failed to return all the contingencies.')
                console.error('Error fetching options:', error)
            }
        }
        setLoading(false)
    }
    const handleChangeContingencyAutocomplete = (event, selection) => {
        setContingencySelection(selection.suggestion)
    }

    // Other functions
    const handleAdd = () => {
        if (constraintSelection === null || contingencySelection === null) {
            showAlert('Something went wrong')
        } else {
            let o = {
                rho: constraintSelection.rho,
                constraint_name: constraintSelection.constraint_name,
                gamma: contingencySelection.tau,
                contingency_name: contingencySelection.contingency_name,
            }
            setConstraintsToSend([...constraintsToSend, o])
            setConstraintSearchResults([])
            setContingencySearchResults([])
        }
    }

    const handleDeletion = (index) => {
        const newList = constraintsToSend.filter((_, i) => i !== index)
        setConstraintsToSend(newList)
    }

    const handleButtonStart = async (term) => {
        setLoading(true)
        setStartButtonStatus(false)
        try {
            const res = await startFlowDecompRun(
                pipelineId,
                constraintsToSend,
                scenariosSelected
            )
            if (res.error !== null) {
                showAlert('Failed to kick off the tool.')
            } else {
                const dataInResponse = res.data
                if (dataInResponse.error !== null) {
                    showAlert('There is a problem: ' + dataInResponse.error)
                } else {
                    const runId = dataInResponse.data.run_id
                    alert('Started tool with ID: ' + runId.toString())
                    setConstraintsToSend([])
                    setScenarioOptions([])
                    setScenariosSelected([])
                }
            }
        } catch (error) {
            showAlert('Failed to start the tool.')
            console.error('Error fetching options:', error)
        }
        setLoading(false)
        setStartButtonStatus(true)
    }

    useEffect(() => {
        const loadInitialData = async () => {
            setLoading(true)
            try {
                const res = await getDAM3Scenarios(pipelineId)

                if (res.error !== null) {
                    showAlert('Failed to get the scenarios.')
                } else {
                    setScenarioOptions(res.data)
                }
            } catch (error) {
                setScenarioOptions([])
                showAlert('Failed to get the scenarios.')
                console.error('Error fetching options:', error)
            }
            setLoading(false)
        }

        loadInitialData()
    }, [showAlert, pipelineId])

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
        >
            <Grid item xs={12}>
                {loading && (
                    <div style={{ margin: '32px 0' }}>
                        <LinearProgress variant="indeterminate" />
                    </div>
                )}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">
                    Select the constraints to run
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>Constraint</Typography>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
                <Autocomplete
                    placeholder="Constraint"
                    getSuggestions={getConstraintSuggestions}
                    suggestions={constraintSearchResults}
                    renderSuggestion={renderConstraintSuggestion}
                    onSuggestionSelected={(event, suggestion) =>
                        handleChangeConstraintAutocomplete(event, suggestion)
                    }
                    inputValue={get(
                        constraintSelection,
                        'constraint_name',
                        ''
                    ).toString()}
                />
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}>
                <Typography>Contingency</Typography>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
                <Autocomplete
                    placeholder="Contingency"
                    getSuggestions={getContingencySuggestions}
                    suggestions={contingencySearchResults}
                    renderSuggestion={renderContingencySuggestion}
                    onSuggestionSelected={(event, suggestion) =>
                        handleChangeContingencyAutocomplete(event, suggestion)
                    }
                    inputValue={get(
                        contingencySelection,
                        'contingency_name',
                        ''
                    ).toString()}
                />
            </Grid>
            <Grid item xs={3}>
                <Button
                    disabled={
                        constraintSelection === null ||
                        contingencySelection === null
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleAdd}
                >
                    Add
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6">Constraints to send</Typography>
            </Grid>
            <Grid item xs={4}>
                <List>
                    {constraintsToSend.map((item, index) => (
                        <ListItem key={index}>
                            <ArrowForwardIosIcon key={index} />
                            <ListItemText
                                primary={`${item.constraint_name} (rho: ${item.rho}), ${item.contingency_name} (gamma: ${item.gamma})`}
                            />
                            <IconButton
                                color="primary"
                                onClick={() => handleDeletion(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12}>
                <Typography variant="h4">
                    Select the scenarios to run
                </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
                <List>
                    {scenarioOptions.map((number) => (
                        <ListItem
                            key={number}
                            button
                            onClick={handleToggleScenario(number)}
                        >
                            <ArrowForwardIosIcon key={number} />
                            <ListItemText primary={`    ${number}`} />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    onChange={handleToggleScenario(number)}
                                    checked={
                                        scenariosSelected.indexOf(number) !== -1
                                    }
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12}>
                <Button
                    disabled={
                        constraintsToSend.length === 0 ||
                        scenariosSelected.length === 0 ||
                        !startButtonStatus
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleButtonStart}
                >
                    Start Constraint Decomposition
                </Button>
            </Grid>
        </Grid>
    )
}

export default DAM3FDOnDemandStarter
