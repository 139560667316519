import { Grid, InputBase, Tooltip } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/styles'
import HelpIcon from '@material-ui/icons/Help'
import { get } from 'lodash'
const useStyles = makeStyles({
    gridItem: {
        padding: '.75rem 0',
        width: '100%',
    },
    gridContainer: {
        width: '80%',
        padding: '2rem 5rem',
    },
    navButtonsGrid: {
        padding: '1.2rem 0',
        display: 'flex',
        width: '25%',
    },
    input: {
        display: 'flex',
    },
    saveButton: {
        width: '100px',
        marginRight: '10px',
    },
    cancelButton: {
        width: '100px',
    },
    tooltip: {
        paddingTop: '20px',
        paddingLeft: '20px',
    },
})

export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase)

const ObjectFieldTemplate = ({ title, properties }) => {
    const classes = useStyles()

    return (
        <>
            <Grid
                container={true}
                alignItems="flex-start"
                spacing={3}
                className={classes.gridContainer}
            >
                {properties.map((element, index) => (
                    <Grid
                        item={true}
                        xs={get(
                            element,
                            'content.props.uiSchema.ui:size',
                            null
                        )}
                        key={index}
                        className={classes.gridItem}
                    >
                        {get(
                            element,
                            'content.props.uiSchema.ui:tooltip',
                            null
                        ) ? (
                            <Grid container alignItems="center" direction="row">
                                <Grid item xs={11}>
                                    {element.content}
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip
                                        className={classes.tooltip}
                                        title={
                                            <span
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {
                                                    element.content.props
                                                        .uiSchema['ui:tooltip']
                                                }
                                            </span>
                                        }
                                        placement="top-end"
                                    >
                                        <HelpIcon />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        ) : (
                            <div>{element.content}</div>
                        )}
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default ObjectFieldTemplate
