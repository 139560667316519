import React from 'react'
import {
    Box,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RemoveIcon from '@material-ui/icons/Remove'

const Notes = ({ notes }) => {
    return (
        <Box paddingTop={5}>
            <Grid container>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="notes-content"
                            id="notes-header"
                        >
                            <Typography variant="h6">Notes</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <List>
                                    {notes?.map((note, index) => (
                                        <ListItem key={index}>
                                            <ListItemIcon>
                                                <RemoveIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={note} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Notes
