import React, { useEffect, useState } from 'react'
import {
    getMiniMasterContributionsForPath,
    getMiniMasterDistinctSourcesAndSinks,
    getMiniMasterPathData,
    getMiniMasterRunVersion,
} from '../../../api'
import { addIdsToRows, generateColumns } from '../../../Utils/DataTableUtil'
import {
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import TaskChart from '../TaskChart'
import { SearchableSelect } from '../../../SearchableSelect/SearchableSelect'

const MiMAnalysisByPath = (props) => {
    const { pipelineId, showAlert } = props
    const [scenarioOptions, setScenarioOptions] = useState([])
    const [selectedScenarioOption, setSelectedScenarioOption] = useState('')
    const [sources, setSources] = useState([])
    const [sinks, setSinks] = useState([])
    const [selectedSource, setSelectedSource] = useState('')
    const [selectedSink, setSelectedSink] = useState('')
    const [pathData, setPathData] = useState([])
    const [loading, setLoading] = useState(false)
    const [stackedBarDataObl, setStackedBarDataObl] = useState(null)
    const [stackedBarDataOpt, setStackedBarDataOpt] = useState(null)

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const res = await getMiniMasterRunVersion(pipelineId)

                if (res.error !== null) {
                    showAlert(
                        'Failed to retrieve run versions.  Please wait and try again.'
                    )
                } else {
                    setScenarioOptions(
                        res.data.map((option) => ({
                            value: option,
                            label: option,
                        }))
                    )
                }
            } catch (error) {
                showAlert(
                    'Failed to retrieve run versions.  Please wait and try again.'
                )
                console.error('Error fetching options:', error)
            }
        }

        fetchOptions()
    }, [showAlert, pipelineId])

    const handleScenarioChange = async (event) => {
        const selectedValue = event.target.value
        setSelectedScenarioOption(selectedValue)
        setLoading(true)
        const res = await getMiniMasterDistinctSourcesAndSinks(pipelineId)

        if (res.error !== null) {
            showAlert(
                'Failed to retrieve all paths.  Please wait and try again.'
            )
        } else {
            setSources(
                res.data.sources.map((option) => ({
                    value: option,
                    label: option,
                }))
            )
            setSinks(
                res.data.sinks.map((option) => ({
                    value: option,
                    label: option,
                }))
            )
        }
        setLoading(false)
    }

    const retrievePathData = async (source, sink) => {
        setLoading(true)
        setPathData([])
        setStackedBarDataOpt(null)
        setStackedBarDataObl(null)

        let res = await getMiniMasterContributionsForPath(
            pipelineId,
            selectedScenarioOption,
            source,
            sink,
            'OBL'
        )
        if (res.error !== null) {
            showAlert(
                'Failed to retrieve contributions data for obligations.  Please wait and try again.'
            )
        } else {
            setStackedBarDataObl(res.data)
        }

        res = await getMiniMasterContributionsForPath(
            pipelineId,
            selectedScenarioOption,
            source,
            sink,
            'OPT'
        )

        if (res.error !== null) {
            showAlert(
                'Failed to retrieve contributions data for options.  Please wait and try again.'
            )
        } else {
            setStackedBarDataOpt(res.data)
        }

        res = await getMiniMasterPathData(
            pipelineId,
            selectedScenarioOption,
            source,
            sink
        )

        if (res.error !== null) {
            showAlert(
                'Failed to retrieve path data.  Please wait and try again.'
            )
        } else {
            setPathData(addIdsToRows(res.data))
        }
        setLoading(false)
    }

    const handleSourceChange = async (event) => {
        const selectedValue = event.target.value

        setSelectedSource(selectedValue)

        if (selectedSink === '') return

        await retrievePathData(selectedValue, selectedSink)
    }

    const handleSinkChange = async (event) => {
        const selectedValue = event.target.value
        setSelectedSink(selectedValue)

        if (selectedSource === '') return

        await retrievePathData(selectedSource, selectedValue)
    }

    const tooltip = {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]

            return (
                '<ul>' +
                '<li><b>Restriction Name</b>: ' +
                data.x +
                '&nbsp;</li>' +
                '<li><b>Value ($/MWh)</b>: ' +
                data.y +
                '</li>' +
                '<li><b>elv</b>: ' +
                data.elv +
                '</li>' +
                '<li><b>elv_pure</b>: ' +
                data.elv_pure +
                '</li>' +
                '<li><b>Contingency</b>: ' +
                data.contingency +
                '</li>' +
                '</ul>'
            )
        },
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={3}>
                <Typography>Choose a scenario to begin</Typography>
            </Grid>
            <Grid item xs={3}>
                <Select
                    disabled={loading}
                    value={selectedScenarioOption}
                    onChange={handleScenarioChange}
                    displayEmpty
                    fullWidth
                >
                    <MenuItem value="" disabled>
                        Scenario
                    </MenuItem>
                    {scenarioOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={4}>
                {loading && (
                    <div style={{ margin: '32px 0' }}>
                        <LinearProgress variant="indeterminate" />
                    </div>
                )}
            </Grid>
            <Grid item xs={6}>
                <SearchableSelect
                    key={selectedSource}
                    label="Select Source"
                    value={selectedSource}
                    onChange={handleSourceChange}
                    options={sources}
                    keyPropFn={(option) => option.value}
                    valuePropFn={(option) => option.value}
                    showAll
                    removeSelectionText
                    alwaysFocusTextFieldOnEnter
                    formControlProps={{ fullWidth: true }}
                />
            </Grid>
            <Grid item xs={6}>
                <SearchableSelect
                    key={selectedSink}
                    label="Select Sink"
                    value={selectedSink}
                    onChange={handleSinkChange}
                    options={sinks}
                    keyPropFn={(option) => option.value}
                    valuePropFn={(option) => option.value}
                    showAll
                    removeSelectionText
                    alwaysFocusTextFieldOnEnter
                    formControlProps={{ fullWidth: true }}
                />
            </Grid>
            <Grid item xs={12}>
                {pathData.length > 0 && (
                    <DataGrid
                        rows={pathData}
                        columns={generateColumns(pathData)}
                        autoHeight={true}
                        pageSize={5}
                        density={'compact'}
                        rowsPerPageOptions={[5]}
                        fullWidth={true}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {stackedBarDataOpt !== null &&
                    stackedBarDataOpt.data.series.length > 0 && (
                        <div>
                            <Typography variant="h6" color="primary">
                                {' '}
                                Options
                            </Typography>
                            <TaskChart
                                visualization={stackedBarDataOpt}
                                tooltip={tooltip}
                            />
                        </div>
                    )}
                {stackedBarDataOpt !== null &&
                    stackedBarDataOpt.data.series.length === 0 && (
                        <Grid item xs={8}>
                            <Typography variant="body1">
                                No contributions data for options.
                            </Typography>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                        </Grid>
                    )}
            </Grid>
            <Grid item xs={12}>
                {stackedBarDataObl !== null &&
                    stackedBarDataObl.data.series.length > 0 && (
                        <div>
                            <Typography variant="h6" color="primary">
                                {' '}
                                Obligations
                            </Typography>
                            <TaskChart
                                visualization={stackedBarDataObl}
                                tooltip={tooltip}
                            />
                        </div>
                    )}
                {stackedBarDataObl !== null &&
                    stackedBarDataObl.data.series.length === 0 && (
                        <Grid item xs={8}>
                            <Typography variant="body1">
                                No contributions data for obligations.
                            </Typography>
                            <span>&nbsp;</span>
                            <span>&nbsp;</span>
                        </Grid>
                    )}
            </Grid>
        </Grid>
    )
}

export default MiMAnalysisByPath
