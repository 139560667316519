import { MenuItem, Typography } from '@material-ui/core'
import Autocomplete from '../Autocomplete'
import { useState, useEffect } from 'react'
import axios from 'axios'

const NodeSearch = (props) => {
    const { onChange, options, formContext } = props

    const [auctionMonth, setAuctionMonth] = useState(null)
    const [auctionYear, setAuctionYear] = useState(null)
    const [networkModelType, setNetworkModelType] = useState(null)
    const [node, setNode] = useState(null)
    const [nodeSearchResults, setNodeSearchResults] = useState([])

    useEffect(() => {
        if (formContext?.auctionPipelineSettings) {
            setAuctionMonth(formContext.auctionPipelineSettings.auctionMonth)
            setAuctionYear(formContext.auctionPipelineSettings.auctionYear)
            setNetworkModelType(
                formContext.auctionPipelineSettings.networkModelType
            )
        }
    }, [
        setAuctionMonth,
        setAuctionYear,
        setNetworkModelType,
        formContext.auctionPipelineSettings,
    ])

    const handleChange = (event, selection) => {
        setNode(selection.suggestion)
        event.preventDefault()
        onChange(selection.suggestion)
    }

    const itemInvalid = (params) => {
        return params === null || params === '' || params === undefined
    }

    const getSuggestions = async (term) => {
        var month
        setAuctionMonth((currentState) => {
            month = currentState
            return currentState
        })

        var year
        setAuctionYear((currentState) => {
            year = currentState
            return currentState
        })

        var type
        setNetworkModelType((currentState) => {
            type = currentState
            return currentState
        })

        let searchData = {
            node_name: term,
            auction_month: month,
            auction_year: year,
            network_model_type: type,
        }

        let res = await axios.post(
            '/pipeline/search_auction_pipeline_nodes',
            searchData
        )
        if (res.status !== 200) {
            setNodeSearchResults([])
        } else {
            setNodeSearchResults(res.data)
        }
    }

    const renderSuggestion = (suggestion, { isHighlighted }) => {
        return (
            <MenuItem
                selected={isHighlighted}
                component="div"
                style={{ width: '100%' }}
            >
                <Typography variant="body2">{suggestion}</Typography>
            </MenuItem>
        )
    }

    return (
        <Autocomplete
            placeholder={options.title}
            disabled={
                itemInvalid(auctionMonth) ||
                itemInvalid(auctionYear) ||
                itemInvalid(networkModelType)
            }
            getSuggestions={getSuggestions}
            suggestions={nodeSearchResults}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(event, suggestion) =>
                handleChange(event, suggestion)
            }
            inputValue={node}
        />
    )
}
export default NodeSearch
