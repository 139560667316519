import Layout from '../../Layout'
import { withRouter } from 'react-router'
import { Component } from 'react'
import {
    Grid,
    TextField,
    Typography,
    withStyles,
    Button,
    IconButton,
    CircularProgress,
} from '@material-ui/core'
import axios from 'axios'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

const styles = (theme) => ({
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
    },
    gridItem: {
        padding: '1.2rem 0',
        display: 'flex',
        width: '100%',
    },
    navButtonsGrid: {
        padding: '1.2rem 0',
        display: 'flex',
        width: '25%',
    },
    nameInput: {
        display: 'flex',
        width: '90%',
    },
    uploadFileInput: {
        width: '75%',
        float: 'left',
    },
    saveButton: {
        width: '100px',
        marginRight: '10px',
    },
    cancelButton: {
        width: '100px',
    },
})

class PipelineSetting extends Component {
    constructor(props) {
        super(props)
        const id = this.props.match.params.id

        this.state = {
            id: id,
            pipelineSetting: {},
            loading: id !== undefined,
            files: null,
            name: null,
        }

        this.createPipelineSetting = this.createPipelineSetting.bind(this)
    }

    handleNameChange = (event) => {
        this.setState({
            name: event.target.value,
        })
    }

    handleFileUploadChange = (event) => {
        const files = []

        Array.from(event.target.files).forEach((file) => {
            files.push(file)
        })

        this.setState({
            files: files,
        })
    }

    async createPipelineSetting() {
        const { history, settings_type } = this.props
        this.setState({
            loading: true,
        })
        var formData = new FormData()
        formData.append('name', this.state.name)
        this.state.files.forEach((file) => {
            formData.append(file.name, file, file.name)
        })
        try {
            const res = await axios({
                method: 'POST',
                url: `/pipeline_settings/create?settings_type=${settings_type}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
            })
            if (res.status === 200 && res.data.failed_uploads.length === 0) {
                history.push(`/${settings_type}/${res.data.id}`)
                history.go(0)
            } else if (
                res.status === 200 &&
                res.data.failed_uploads.length > 0
            ) {
                this.setState({
                    loading: false,
                })
                alert('Failed to ingest files: ' + res.data.failed_uploads)
                history.push(`/${settings_type}/${res.data.id}`)
                history.go(0)
            }
        } catch (e) {
            alert(e)
            this.setState({
                loading: false,
            })
        }
    }

    async handleFileDownloadClick(id, file_name) {
        const { settings_type } = this.props

        const res = await axios.get(
            `/pipeline_settings/file/${id}?settings_type=${settings_type}`
        )
        const downloadURL = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = downloadURL
        link.setAttribute('download', file_name)
        document.body.appendChild(link)
        link.click()
    }

    async componentDidMount() {
        const { settings_type } = this.props

        if (this.state.id !== undefined) {
            const results = await axios.get(
                `/pipeline_settings/${this.state.id}?settings_type=${settings_type}`
            )

            this.setState({
                pipelineSetting: results.data.pipeline_setting,
                loading: false,
            })
        }
    }

    saveDisabled() {
        return (
            this.state.name === null ||
            this.state.name === undefined ||
            this.state.name === '' ||
            this.state.files === null ||
            this.state.files === undefined
        )
    }

    render() {
        const { classes, history, settings_type } = this.props

        const previousPage =
            settings_type === 'run_configuration'
                ? 'run_configurations'
                : settings_type === 'run_modification'
                  ? 'run_modifications'
                  : 'custom_auction_modifications'
        const createHeader =
            settings_type === 'run_configuration'
                ? 'Create Run Configuration'
                : settings_type === 'run_modification'
                  ? 'Create Run Modification'
                  : 'Create Custom Auction Modification'

        return (
            <Layout>
                {this.state.loading ? (
                    <div className={classes.loadingIndicator}>
                        <CircularProgress color="primary" size={75} />
                    </div>
                ) : (
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        style={{ width: '80%', padding: '2rem 5rem' }}
                    >
                        {this.state.id === undefined ? (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h3" color="primary">
                                        {createHeader}
                                    </Typography>
                                </Grid>
                                <Grid container direction="row">
                                    <Grid
                                        item
                                        xs={8}
                                        className={classes.gridItem}
                                    >
                                        <TextField
                                            variant="outlined"
                                            className={classes.nameInput}
                                            label="Name"
                                            onChange={this.handleNameChange}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        className={classes.gridItem}
                                    >
                                        <Button
                                            variant="contained"
                                            component="label"
                                            color="primary"
                                            className={classes.uploadFileInput}
                                        >
                                            Upload File(s)
                                            <input
                                                onChange={
                                                    this.handleFileUploadChange
                                                }
                                                type="file"
                                                multiple="multiple"
                                                hidden
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                                {this.state.files !== null && (
                                    <Grid container justifyContent="center">
                                        {this.state.files.map((file) => (
                                            <Grid
                                                key={file.name}
                                                item
                                                xs={12}
                                                className={classes.gridItem}
                                            >
                                                - {file.name}
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.navButtonsGrid}
                                >
                                    <Grid container>
                                        <Button
                                            disabled={this.saveDisabled()}
                                            className={classes.saveButton}
                                            onClick={this.createPipelineSetting}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            className={classes.cancelButton}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() =>
                                                history.push(`/${previousPage}`)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <h1>
                                        {this.state.pipelineSetting.id} -{' '}
                                        {this.state.pipelineSetting.name}
                                    </h1>
                                </Grid>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <TextField
                                            className={classes.nameInput}
                                            label="Created By"
                                            disabled={true}
                                            value={
                                                this.state.pipelineSetting
                                                    .created_by || ''
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            className={classes.nameInput}
                                            label="Created Date"
                                            disabled={true}
                                            value={
                                                this.state.pipelineSetting
                                                    .created_date || ''
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                {this.state.pipelineSetting.files !==
                                    undefined && (
                                    <Grid
                                        container
                                        justifyContent="center"
                                        direction="row"
                                    >
                                        {this.state.pipelineSetting.files.map(
                                            (file) => (
                                                <Grid
                                                    key={file.id}
                                                    container
                                                    direction="row"
                                                >
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        className={
                                                            classes.gridItem
                                                        }
                                                    >
                                                        - {file.filename}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={4}
                                                        className={
                                                            classes.gridItem
                                                        }
                                                    >
                                                        {file.file_type}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={async () => {
                                                                await this.handleFileDownloadClick(
                                                                    file.id,
                                                                    file.filename
                                                                )
                                                            }}
                                                        >
                                                            <CloudDownloadIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.navButtonsGrid}
                                >
                                    <Button
                                        className={classes.cancelButton}
                                        variant="contained"
                                        color="secondary"
                                        onClick={() =>
                                            history.push(`/${previousPage}`)
                                        }
                                    >
                                        Return
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            </Layout>
        )
    }
}

export default withRouter(withStyles(styles)(PipelineSetting))
